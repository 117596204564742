import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  TextField, 
  Paper, 
  TableContainer, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Tabs, 
  Tab, 
  CardContent, 
  Divider, 
  Snackbar, 
  Card, 
  Select, 
  MenuItem 
} from '@mui/material';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import API from '../../../API';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { styled } from '@mui/material/styles';

const ActionCardGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
`;

const StyledCard = styled(Card)`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const PayoutsInvoicing = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;

  const [open, setOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [activeTab, setActiveTab] = useState('payouts');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  // Added state for payout status filter
  const [payoutStatusFilter, setPayoutStatusFilter] = useState('All');

  const { isLoading, error, data: financials } = useQuery(
    ['creator', 'financials'],
    () => API.creatorConnect.getFinancials(username),
    {
      keepPreviousData: true,
    }
  );

  if (isLoading) return <CircularProgress sx={{ color: 'white' }} />;
  if (error)
    return (
      <Typography sx={{ color: 'error' }}>
        Error fetching financials: {error.message}
      </Typography>
    );

  const handleGeneratePO = () => {
    setPoNumber(`TCC-${Math.floor(Math.random() * 1000000)}`);
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleGeneratePO();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenPaymentDialog = (payoutId) => {
    setPaymentDialogOpen(true);
    setPaymentMethod({ payoutId, method: '' });
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const handleInvoice = async () => {
    try {
      await API.creatorConnect.postInvoice({
        user_id: username,
        creator_id: username,
        po_number: poNumber,
        amount,
        payout_date: new Date().toISOString(),
        status: 'Pending',
        blitzpay: true,
        unique_code: poNumber,
        interest_rate: 5,
        client_name: clientName,
        client_email: clientEmail,
        notes,
      });
      handleClose();
      setSnackbarMessage('Invoice sent successfully');
      setSnackbarOpen(true);
    } catch (error) {
      alert(error);
      handleClose();
    }
  };

  const handleDownloadCSV = () => {
    const csvData = [
      ['ID', 'Amount', 'Status', 'Payout Date', 'Blitzpay', 'Fee', 'Unique Code', 'Notes'],
      ...financials.invoices.map((invoice) => [
        invoice.id,
        invoice.amount?.toFixed(2) || '0.0',
        invoice.status,
        invoice.payout_date,
        invoice.blitzpay ? 'Yes' : 'No',
        ((invoice.interest_rate / 100) * invoice.amount).toFixed(2),
        invoice.unique_code,
        invoice.notes,
      ]),
      ...financials.payouts.map((payout) => [
        payout.id,
        payout.amount?.toFixed(2) || '0.0',
        payout.status,
        payout.payout_date,
        payout.blitzpay ? 'Yes' : 'No',
        ((payout.interest_rate / 100) * payout.amount).toFixed(2),
        payout.unique_code,
        payout.notes,
      ]),
    ];

    const csvString = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `Financial_Statement_${new Date().toLocaleDateString()}.csv`);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleConfirmPayment = async () => {
    try {
      await API.creatorConnect.confirmPayment({
        username,
        payoutId: paymentMethod.payoutId,
        paymentMethod: paymentMethod.method,
      });
      setSnackbarMessage('Payment method confirmed successfully');
      setSnackbarOpen(true);
      handleClosePaymentDialog();
    } catch (error) {
      alert(error);
    }
  };

  const handleTabClick = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handler for payout status filter change
  const handlePayoutStatusFilterChange = (event, newValue) => {
    setPayoutStatusFilter(newValue);
  };

  const allPayouts = financials?.payouts || [];
  const totalEarnings = allPayouts.reduce(
    (sum, payout) => sum + (payout.amount || 0),
    0
  );

  const allInvoices = financials?.invoices || [];

  // Define pendingInvoices by filtering allInvoices
  const pendingInvoices = allInvoices.filter(
    (invoice) => invoice.status === 'Pending'
  );

  const unverifiedFunds = pendingInvoices.reduce(
    (sum, invoice) => sum + (invoice.amount || 0),
    0
  );

  // Filter payouts based on payoutStatusFilter
  const filteredPayouts =
    payoutStatusFilter === 'All'
      ? allPayouts
      : allPayouts.filter(
          (payout) =>
            payout.status.toLowerCase() === payoutStatusFilter.toLowerCase()
        );

  return (
    <>
      <Paper
        elevation={2}
        square
        sx={{ width: '100%', padding: 2, mb: 2 }}
      >
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          sx={{ textAlign: 'center' }}
        >
          Payouts & Invoicing
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mb: 4,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            Invoice Someone
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadCSV}
          >
            Download Financial Statement
          </Button>
        </Box>
        <Tabs value={activeTab} onChange={handleTabClick} centered>
          <Tab label="Payouts" value="payouts" />
          <Tab label="Invoices" value="invoices" />
        </Tabs>
      </Paper>
      <ActionCardGrid>
        <StyledCard>
          <CardContent>
            <Typography variant="h6">Balance</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">
              ${Number(totalEarnings).toFixed(2)}
            </Typography>
          </CardContent>
        </StyledCard>
        <StyledCard>
          <CardContent>
            <Typography variant="h6">Unverified Funds</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">
              ${Number(unverifiedFunds).toFixed(2)}
            </Typography>
          </CardContent>
        </StyledCard>
      </ActionCardGrid>

      {activeTab === 'payouts' && (
        <>
          {/* Tabs for filtering payouts by status */}
          <Tabs
            value={payoutStatusFilter}
            onChange={handlePayoutStatusFilterChange}
            centered
            sx={{ marginTop: 2 }}
          >
            <Tab label="All" value="All" />
            <Tab label="Approved" value="Approved" />
            <Tab label="Pending" value="Pending" />
            <Tab label="Cancelled" value="Cancelled" />
          </Tabs>

          <TableContainer
            sx={{
              width: '80%',
              margin: 'auto',
              overflowX: 'auto',
              mt: 4,
            }}
            component={Paper}
          >
            <Table aria-label="payouts table">
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Payout Date</TableCell>
                  <TableCell>Blitzpay</TableCell>
                  <TableCell>Fee</TableCell>
                  <TableCell>Unique Code</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPayouts.length > 0 ? (
                  filteredPayouts.map((payout) => {
                    const amount = payout.amount || 0;
                    const fee = (payout.interest_rate / 100) * amount;

                    return (
                      <TableRow key={payout.id}>
                        <TableCell>${amount.toFixed(2)}</TableCell>
                        <TableCell>{payout.status}</TableCell>
                        <TableCell>{payout.payout_date}</TableCell>
                        <TableCell>
                          {payout.blitzpay ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell>${fee.toFixed(2)}</TableCell>
                        <TableCell>{payout.unique_code}</TableCell>
                        <TableCell>{payout.notes}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleClickOpenPaymentDialog(payout.id)
                            }
                          >
                            Confirm Payment
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography align="center">
                        No payouts available for the selected status.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {activeTab === 'invoices' && (
        <>
          <TableContainer
            sx={{
              width: '80%',
              margin: 'auto',
              overflowX: 'auto',
              mt: 4,
            }}
            component={Paper}
          >
            <Table aria-label="invoices table">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice ID</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Payout Date</TableCell>
                  <TableCell>Blitzpay</TableCell>
                  <TableCell>Fee</TableCell>
                  <TableCell>Unique Code</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingInvoices && pendingInvoices.length > 0 ? (
                  pendingInvoices.map((invoice) => {
                    const amount = invoice.amount || 0;
                    const fee = (invoice.interest_rate / 100) * amount;

                    return (
                      <TableRow key={invoice.id}>
                        <TableCell>{invoice.id}</TableCell>
                        <TableCell>${amount.toFixed(2)}</TableCell>
                        <TableCell>{invoice.status}</TableCell>
                        <TableCell>{invoice.payout_date}</TableCell>
                        <TableCell>
                          {invoice.blitzpay ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell>${fee.toFixed(2)}</TableCell>
                        <TableCell>{invoice.unique_code}</TableCell>
                        <TableCell>{invoice.notes}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            component={Link}
                            to={`/creatorinvoice/${invoice.id}`}
                            sx={{ mt: 2 }}
                          >
                            View Invoice
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Typography align="center">
                        No pending invoices available
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Dialog
        open={paymentDialogOpen}
        onClose={handleClosePaymentDialog}
      >
        <DialogTitle>Select Payment Method</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please choose your preferred payment method.
          </DialogContentText>
          <Select
            fullWidth
            value={paymentMethod.method}
            onChange={(e) =>
              setPaymentMethod({
                ...paymentMethod,
                method: e.target.value,
              })
            }
          >
            <MenuItem value="BLITZPAY">
              BlitzPay (Immediate Payment)
            </MenuItem>
            <MenuItem value="NET30">
              Net 30 (30-Day Payment)
            </MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentDialog}>Cancel</Button>
          <Button onClick={handleConfirmPayment}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invoice Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details for the client you want to invoice.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Client Name"
            fullWidth
            variant="outlined"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Client Email"
            fullWidth
            variant="outlined"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Amount"
            fullWidth
            variant="outlined"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            variant="outlined"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleInvoice}>Send Invoice</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={handleCloseSnackbar}
          >
            Close
          </Button>
        }
      />
    </>
  );
};

export default PayoutsInvoicing;
