import React, { useState, useEffect, useMemo } from 'react'
import { Avatar, Backdrop, Box, CircularProgress, Divider, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Pagination, Paper, Skeleton, TextField, Typography } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import useIsDesktop from '../../Hooks/useIsDesktop';
import { Link, useOutlet, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../API';

export const styles = {
    wholeHeightMinusTop: {
        '@media (min-width:0px)': {
            height: { xs: 'calc(100vh - 56px - 48px)', md: 'calc(100vh - 48px)' },
            '@media (orientation: landscape)': {
                height: { xs: 'calc(100vh - 48px - 48px)', md: 'calc(100vh - 48px)' },
            },
        },
        '@media (min-width:600px)': {
            height: { xs: 'calc(100vh - 64px - 48px)', md: 'calc(100vh - 48px)' }
        }
    }
}

function CreatorEntry({ creator, currentCreator }) {
    return (
        <ListItemButton component={Link} to={creator.creator} sx={{backgroundColor: creator.creator == currentCreator ? 'action.selected' : 'transparent'}}>
            <ListItemAvatar>
                <Avatar src={creator.pfphref || undefined}>
                    {!creator.pfphref && creator.creator[0]}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={creator.creator} secondary={creator.notes_content_style || <>&nbsp;</>} secondaryTypographyProps={{ sx: { textWrap: 'nowrap' } }} />
        </ListItemButton>
    )
}

function EmptyCreator({ props }) {
    return (
        <ListItemButton>
            <ListItemAvatar>
                <Skeleton variant="circular">
                    <Avatar>
                    </Avatar>
                </Skeleton>
            </ListItemAvatar>
            <ListItemText primary={<Skeleton animation='wave'></Skeleton>} secondary={<Skeleton animation='wave'></Skeleton>} secondaryTypographyProps={{ sx: { textWrap: 'nowrap' } }} />
        </ListItemButton>
    )
}


export default function AgencyCreators({ }) {
    const isDesktop = useIsDesktop();
    const { creatorId } = useParams();
    const outlet = useOutlet();
    const [page, setPage] = useState(0);
    const itemsPerPage = 20;
    const [currentSearch, setCurrentSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const { data: creators, isLoading } = useQuery({
        queryKey: ['creators'],
        queryFn: () => API.creators.list({ only_agency: true }),
        refetchOnWindowFocus: false
    });

    //Debounces search bar to make it more performant
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setCurrentSearch(searchInput);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId); //Cancels the debounce when more terms come in
    }, [searchInput]);

    const filteredCreators = useMemo(
        () => !creators ? [] : creators.filter((creator) => {
            return currentSearch === "" || Object.values(creator).some((val) => typeof val === "string" && val.toLowerCase().includes(currentSearch.toLowerCase()));
        }),
        [currentSearch, creators]
    );

    return (
        <>
            {(!isDesktop && outlet) || <Box sx={{ ...styles.wholeHeightMinusTop, paddingInline: { xs: 0, md: 2 }, paddingBlockStart: { xs: 0, md: 2 }, paddingBlockEnd: { xs: 0, md: 1 }, display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Paper sx={{
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%', md: '22.5rem' },
                }}
                    elevation={1} square={!isDesktop}>
                    <Box sx={{ padding: 2 }}>
                        <TextField
                            value={searchInput}
                            onChange={(event)=>setSearchInput(event.target.value)}
                            variant='outlined'
                            fullWidth size='small'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <SearchRounded>
                                        </SearchRounded>
                                    </InputAdornment>)
                            }}>
                        </TextField>
                    </Box>
                    <Divider></Divider>
                    <Box sx={{ flex: 1, overflowY: 'auto', overflowX: 'clip' }}>
                        <Box>
                            <List>
                                {!!creators && filteredCreators.slice(page * itemsPerPage, Math.min((page * itemsPerPage) + itemsPerPage), filteredCreators.length).map((creator) => (
                                    <CreatorEntry key={creator.creator} creator={creator} currentCreator={creatorId}></CreatorEntry>
                                    ))}
                                {isLoading && [0, 1, 2, 4, 5, 6, 7].map((index) => (<EmptyCreator key={index}></EmptyCreator>))}
                            </List>
                        </Box>
                    </Box>
                    <Divider></Divider>
                    <Box sx={{ paddingBlock: 2 }}>
                        <Pagination
                            sx={{ marginInline: 'auto', maxWidth: 'fit-content' }}
                            siblingCount={0}
                            count={Math.floor(filteredCreators.length / itemsPerPage)} page={page + 1}
                            onChange={(event, value) => setPage(value - 1)}>
                        </Pagination>
                    </Box>
                </Paper>
                {isDesktop && <Paper sx={{ maxHeight: '100%', overflowY: 'auto', flex: 1 }} elevation={1} >
                    {outlet ||
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <Typography variant='h4'>Select a Creator</Typography>
                        </Box>}
                </Paper>}
            </Box>}
        </>
    )
}
