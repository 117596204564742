import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress, Typography
} from '@mui/material';
import client from '../../../../../API';

const UpdateDialog = ({ open, onClose, userData }) => {
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleSendUpdate = async () => {
    setIsSending(true);
    const updates = userData.map(user => ({
      email: user.email,
      subject: emailSubject.replace(/{first_name}/g, user.first_name),
      body: emailBody.replace(/{first_name}/g, user.first_name)
    }));

    try {
      const result = await client.crm.update(updates);
      console.log('Updates sent:', result);
      console.log('Updates sent:', result);

      // Update CRM contacts with last_contacted and note
      
      await client.crm.update_contacts({ updates, note: `last email - ${emailSubject}` });
      
      onClose();
    } catch (error) {
      console.error('Failed to send updates:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
  <DialogTitle>Send Update</DialogTitle>
  <DialogContent>
    <Typography variant="body1">
      Selected Users:
      {userData.length > 0 ? (
        userData.map(user => (
          <Typography key={user.id}>{user.email}</Typography>
        ))
      ) : (
        <Typography>No user selected.</Typography>
      )}
    </Typography>
    <TextField
      label="Email Subject"
      variant="outlined"
      fullWidth
      margin="normal"
      value={emailSubject}
      onChange={(e) => setEmailSubject(e.target.value)}
    />
    <TextField
      label="Email Body"
      variant="outlined"
      multiline
      rows={10}
      fullWidth
      margin="normal"
      value={emailBody}
      onChange={(e) => setEmailBody(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={onClose} disabled={isSending}>Cancel</Button>
    <Button onClick={handleSendUpdate} color="primary" variant="contained" disabled={isSending}>
      {isSending ? <CircularProgress size={24} /> : 'Send'}
    </Button>
  </DialogActions>
</Dialog>

  );
};

export default UpdateDialog;
