import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useQuery } from "react-query";
import client from "../../../../API"; // Adjust the import path
import CampaignsContainers from "./creatorContainers.js"; // Adjust the import path as needed
import BookingDialog from "../../../Misc/bookingdialog"; // Adjust the import path as needed
import { platformRatesListFromCreator } from "../../../../Utils/constants.js"; // Adjust the import path

const AgencyDetailsPanel = ({ agencyDetails, viewMode }) => {
  const creators = agencyDetails.creators;
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [promotionData, setPromotionData] = useState([]);
  const [isLoadingCreator, setIsLoadingCreator] = useState(false);

  const handleInviteClick = async (creatorName) => {
    setIsLoadingCreator(true);
    try {
      const creatorData = await client.creators.fetchDetails(creatorName);
      setSelectedCreator(creatorData.creator);
      const rateList = platformRatesListFromCreator(creatorData.creator);
      setPromotionData(rateList);
      setBookingDialogOpen(true);
    } catch (error) {
      console.error("Failed to fetch creator details:", error);
      alert("Failed to fetch creator details. Please try again.");
    } finally {
      setIsLoadingCreator(false);
    }
  };

  const handleBookingSubmission = async (bookingDetails) => {
    try {
      const result = await client.partnerships.bookPublic({
        name: `${bookingDetails.partnershipName} - ${bookingDetails.selectedPromotion}`,
        creator: bookingDetails.creatorId,
        description: bookingDetails.details,
        email: bookingDetails.email,
        proposal_date: bookingDetails.selectedDate,
        total_sum: bookingDetails.rate,
        unique_code: `promo_${bookingDetails.creatorId}_${new Date().getTime()}`,
        notes: bookingDetails.details,
        finish_date: bookingDetails.selectedDate,
      });
      console.log("Booking successful:", result);
      alert("Booking successful! Please check your email for further instructions.");
      setBookingDialogOpen(false);
      return true;
    } catch (error) {
      console.error("Error submitting booking:", error);
      alert(`Error submitting booking: ${error?.response?.data?.error || error?.message}`);
      return false;
    }
  };

  if (!creators || creators.length === 0) {
    return <Typography>No creators found.</Typography>;
  }

  return (
    <>
      {viewMode === "list" ? (
        <Paper sx={{ maxWidth: "800px", margin: "0 auto" }}>
          <List>
            {creators.map((creator, index) => (
              <React.Fragment key={index}>
                <ListItem
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <ListItemText
                    primary={creator.creator ?? ""}
                    secondary={`Following: ${creator.following ?? 0}`}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    {creator.youtube && (
                      <Chip
                        label={`YouTube followers: ${creator.youtube ?? 0}`}
                        color="primary"
                      />
                    )}
                    {creator.instagram && (
                      <Chip
                        label={`Instagram followers: ${creator.instagram ?? 0}`}
                        color="secondary"
                      />
                    )}
                    {creator.tiktok && (
                      <Chip label={`TikTok followers: ${creator.tiktok ?? 0}`} color="success" />
                    )}
                    {creator.youtube_link && (
                      <Typography variant="body2">
                        <a
                          href={creator.youtube_link ?? ""}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View on YouTube
                        </a>
                      </Typography>
                    )}
                    {creator.instagram_link && (
                      <Typography variant="body2">
                        <a
                          href={creator.instagram_link ?? ""}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View on Instagram
                        </a>
                      </Typography>
                    )}
                    {creator.tiktok_link && (
                      <Typography variant="body2">
                        <a
                          href={creator.tiktok_link ?? ""}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View on TikTok
                        </a>
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleInviteClick(creator.creator)}
                    sx={{ mt: 2 }}
                  >
                    Invite to Partnership
                  </Button>
                </ListItem>
                {index < creators.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ) : (
        <CampaignsContainers
          creators={creators}
          handleInviteClick={handleInviteClick}
        />
      )}
      <BookingDialog
        open={bookingDialogOpen}
        onClose={() => setBookingDialogOpen(false)}
        submitBooking={handleBookingSubmission}
        creator={selectedCreator}
        promotionData={promotionData}
      />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoadingCreator}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AgencyDetailsPanel;
