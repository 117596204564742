import { API_ENDPOINTS } from "./endpoints";
import { HttpClient } from "./http-client";

class Client {
  users = {
    login: (input) => HttpClient.post(API_ENDPOINTS.USERS_LOGIN, input),
    dashboard: () => HttpClient.get(API_ENDPOINTS.USERS_DASHBOARD),
    register: (input) => HttpClient.post(API_ENDPOINTS.USERS_REGISTER, input),
    fetchUser: () => HttpClient.get(API_ENDPOINTS.FETCH_USER),
    userKPI: () => HttpClient.get(API_ENDPOINTS.USERS_KPI),
    userDetails: () => HttpClient.get(API_ENDPOINTS.USERS_DETAILS),
    access: (input) => HttpClient.post(API_ENDPOINTS.REQUEST_ACCESS_CRM, input),
    userAdminFetch: () => HttpClient.get(API_ENDPOINTS.USER_ADMIN_FETCH),
    creatorUserAdminFetch: () => HttpClient.get(API_ENDPOINTS.CREATOR_USER_ADMIN_FETCH),

    editUserWithCredits: (input) => HttpClient.post(API_ENDPOINTS.USER_ADMIN_EDIT, input),
    editCreatorWithCredits: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_CREDITS_ADMIN_EDIT, input),

    fetchSharedWith: () => HttpClient.get(API_ENDPOINTS.SHARED_WITH_ADMIN),
    postClient: (input) => HttpClient.post(API_ENDPOINTS.CLIENTS_ADD, input),
    fetchClient: () => HttpClient.get(API_ENDPOINTS.CLIENTS),
    fetchZapFeed: () => HttpClient.get(API_ENDPOINTS.USERS_ZAPFEED),
    updateClient: (id, input) => HttpClient.put(API_ENDPOINTS.UPDATE_CLIENT(id), input),
 
  }
  campaigns = {
    launch: (input) => HttpClient.post(API_ENDPOINTS.LAUNCH_CAMPAIGN, input),

    list: () => HttpClient.get(API_ENDPOINTS.CAMPAIGNS),
    details: (id) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_DETAILS(id)),
    share: (id, input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_SHARE(id), input),
    timeline_edit: (id, input) => HttpClient.put(API_ENDPOINTS.CAMPAIGNS_TIMELINE(id), input),
    timeline_by_creator: (id, creatorId) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_TIMELINE_BY_CREATOR(id, creatorId)),
    listAdmin: () => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_ADMIN),
    listKPI: () => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_ADMIN_KPI),

    create: (input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS, input),
    copy: (input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_COPY, input),
    fetch: (input) => HttpClient.get(`${API_ENDPOINTS.CAMPAIGNS}${input}`),
    update: (input) => HttpClient.post(API_ENDPOINTS.UPDATE_CAMPAIGN, input),
    updateCreatorList: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_CREATOR_LIST, input),
    updateCreatorStats: (input) =>
      HttpClient.post(API_ENDPOINTS.CAMPAIGN_UPDATE_CREATOR_STATS, input),
    updateManager: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_MANAGER, input),
    complete: (input) =>
      HttpClient.post(
        `${API_ENDPOINTS.COMPLETE_CAMPAIGN}/${input.index}`,
        input.params
      ),
    completeCreator: (campaignId, input) =>
      HttpClient.post(API_ENDPOINTS.COMPLETE_CREATOR(campaignId),
        input
      ),
    delete: (input) => HttpClient.post(API_ENDPOINTS.DELETE_CAMPAIGN, input),
    editAdmin: (input) =>
      HttpClient.post(API_ENDPOINTS.EDIT_CAMPAIGN_ADMIN, input),
    deleteCampaignAdmin: (input) =>
      HttpClient.post(API_ENDPOINTS.DELETE_CAMPAIGN_ADMIN, input),
    fetchAdmin: (input) => HttpClient.get(`${API_ENDPOINTS.CAMPAIGNS}${input}`),
    updateCreatorStats: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_CREATORS_STATS, input),
    exportSheet: (input) => HttpClient.post(API_ENDPOINTS.EXPORT_SHEET, input),
    updateCampaignCreatorSpecs: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_CREATOR_CAMPAIGN_SPEC, input),
    updateMetaData: (input) =>
      HttpClient.post(API_ENDPOINTS.UPDATE_META_DATA, input),
    createSimilar: (input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_SIMILAR, input),
    addBonus: (id, input) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_ADDBONUS(id),input ),
    history: (campaignId) => HttpClient.get(API_ENDPOINTS.CAMPAIGNS_HISTORY(campaignId)),
    client: (campaignId, data) =>
      HttpClient.post(API_ENDPOINTS.CAMPAIGNS_CLIENT(campaignId), data),
  
    // Fetch clients assigned to a campaign
    clientFetch: (campaignId) =>
      HttpClient.get(API_ENDPOINTS.CAMPAIGNS_CLIENT_FETCH(campaignId)),
    optout: (campaignId) => HttpClient.post(API_ENDPOINTS.CAMPAIGNS_OPTOUT(campaignId)),

  };

  payouts = {
    list: () => HttpClient.get(API_ENDPOINTS.PAYOUTS),
    create: (input) => HttpClient.post(API_ENDPOINTS.PAYOUTS, input),
    delete: (input) => HttpClient.post(API_ENDPOINTS.PAYOUT_DELETE, input),
    listAdmin: () => HttpClient.get(API_ENDPOINTS.PAYOUTS_ADMIN),
    deleteAdmin: (input) => HttpClient.post(API_ENDPOINTS.DELETE_PAYOUTS_ADMIN, input),
    editAdmin: (input) => HttpClient.post(API_ENDPOINTS.PAYOUTS_EDIT_ADMIN, input),
    createBatch: (input) => HttpClient.post(API_ENDPOINTS.CREATE_BATCH_PAYOUTL, input),
  };

  creators = {
    list: (params = null) => HttpClient.get(API_ENDPOINTS.CREATORS, params),
    listPartners: (params = null) => HttpClient.get(API_ENDPOINTS.CREATORS_PARTNERS, params),
    emails: () => HttpClient.get(API_ENDPOINTS.CREATOR_EMAILS),
    listKPI: () => HttpClient.get(API_ENDPOINTS.CREATORS_KPI),
    listManager: (agencyid) => HttpClient.get(`${API_ENDPOINTS.CREATORS_MANAGER}${agencyid}`),
    add: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_ADD, input),
    signup: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_SIGNUP, input),
    update: (input) => HttpClient.put(API_ENDPOINTS.CREATOR_UPDATE, input),
    delete: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_DELETE, input),
    softDelete: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_SOFT_DELETE, input),
    adminFetch: () => HttpClient.get(API_ENDPOINTS.CREATOR_ADMIN_FETCH),
    adminEmails: () => HttpClient.get(API_ENDPOINTS.ADMIN_CREATOR_EMAILS),

    adminBrandEmailsList: async (query) => {
      const data = await HttpClient.get(API_ENDPOINTS.CREATORCONNECT_ADMIN_EMAIL, query);
      return {
        ...data,
        emails: data.emails.map((email) => ({ ...email, date_received: new Date(email.date_received) })),
      }
    },
    adminBrandEmail: async (email) => {
      const data = await HttpClient.get(API_ENDPOINTS.CREATORCONNECT_ADMIN_EMAIL_GET(email));
      return {
        ...data,
        message: { ...data.message, date_received: new Date(data.message.date_received) },
      }
    },
    adminBrandKeywords: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_ADMIN_EMAIL_KEYWORDS),

    payout: (input) =>
      HttpClient.get(API_ENDPOINTS.CREATOR_PAYOUT_READ, { sheetId: input }),
    data: () => HttpClient.get(API_ENDPOINTS.CREATOR_DATA_READ),
    fetchDetails: (creatorId) => HttpClient.get(`${API_ENDPOINTS.CREATORS_SPEC}${creatorId}`),
    fetchSimilar: (creatorId) => HttpClient.get(`${API_ENDPOINTS.CREATORS_SPEC}${creatorId}/similar`),
    crmUpdate: (input) => HttpClient.post(API_ENDPOINTS.CRM_UPDATE, input),
    fetchAverageReview: (creatorId) => HttpClient.get(API_ENDPOINTS.CREATOR_AVERAGE_REVIEW(creatorId)),
    postReview: (creatorId, input) => HttpClient.post(API_ENDPOINTS.CREATOR_REVIEW(creatorId), input),

  };

  crm = {
    update: (input) => HttpClient.post(API_ENDPOINTS.CRM_UPDATE, input),
    update_contacts: (input) => HttpClient.post(API_ENDPOINTS.CRM_UPDATE_CONTACTS, input),
    update_contact_status: (input) => HttpClient.post(API_ENDPOINTS.CRM_UPDATE_CONTACT_STATUS, input),
    creator_update_status: (input) => HttpClient.post(API_ENDPOINTS.CRM_CREATOR_UPDATE_STATUS, input),
    add: (input) => HttpClient.post(API_ENDPOINTS.CRM_ADD, input),
    creator_creators: () => HttpClient.get(API_ENDPOINTS.CRM_CREATOR_CREATORS),
    creator_fetch: (creatorId) => HttpClient.get(API_ENDPOINTS.CRM_CREATOR_SPEC(creatorId)),
    igdm_creators: () => HttpClient.get(API_ENDPOINTS.CRM_IGDM_CREATORS),
    contacts: () => HttpClient.get(API_ENDPOINTS.CRM_CONTACTS),
  };

  credx = {
    credits: (input) => HttpClient.get(API_ENDPOINTS.CREDX_CREDITS, input),
    subtract_credx: (input) => HttpClient.post(API_ENDPOINTS.SUBTRACT_CREDITS, input),
  };

  twilio = {
    sendCreatorSms: (input) => HttpClient.post(API_ENDPOINTS.TWILIO_SEND_CREATOR_SMS, input),
    fetch: () => HttpClient.get(API_ENDPOINTS.FETCH_MESSAGES),
  };

  creatorConnect = {
    login: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_LOGIN, input, {ignoreAuthError:true}),
    internallogin: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_INTERNALLOGIN, input, {ignoreAuthError:true}),
    connect: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_ADD, input),
    reauth: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_REAUTH, input),
    getReauth: (creator) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_REAUTH, { username: creator }),
    campaigns: () => HttpClient.get(API_ENDPOINTS.creatorConnectCampaigns),
    campaignUpdateStatus: (campaignId, input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_STATUS_CAMPAIGN(campaignId), input),
    payouts: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_PAYOUTS),
    campaignUpdateTimeline: (id, creatorId, input) => HttpClient.put(API_ENDPOINTS.CAMPAIGNS_TIMELINE_BY_CREATOR(id, creatorId), input),
    editCreator: (input) => HttpClient.put(API_ENDPOINTS.CREATORCONNECT_EDIT, input),
    onboard: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_ONBOARD, input),
    editCreatorStripe: (input) => HttpClient.put(API_ENDPOINTS.CREATORCONNECT_EDIT_STRIPE, input),
    postGoogle: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_GOOGLE, input),
    getCreator: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_GET),
    getEmails: (query) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_EMAILS, query),
    getInvoice: (id) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_INVOICE(id)),
    getPartnerships: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_PARTNERSHIPS),
    getPartnershipSingle: (partnershipId) => HttpClient.get(`${API_ENDPOINTS.CREATORCONNECT_PARTNERSHIPS_SINGLE}${partnershipId}`),
    getContacts: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_CONTACTS),
    getUsersByCompany: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_USERS_BY_COMPANY),
    getCredxCredits: (input) => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_CREDX_CREDITS, input),
    postCredxPitch: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_CREDX_PITCH, input),
    postCredxSubstraction: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_CREDX_SUBSTRACT, input),
    getZapFeed: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_ZAPFEED),
    commissions: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_COMMISSIONS),
    getFinancials: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_FINANCIALS),
    getMisc: () => HttpClient.get(API_ENDPOINTS.CREATORCONNECT_MISC),
    postInvoice: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_INVOICE_CREATE, input),
    unreadMessages: () => HttpClient.get(API_ENDPOINTS.CONVERSATION_CREATOR_UNREAD),
    updateCreatorFields: (campaignId, input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_UPDATE_FIELDS(campaignId), input),
    confirmPayment: (input) => HttpClient.post(API_ENDPOINTS.CREATORCONNECT_CONFIRM_PAYOUT, input),

  }

  invoices = {
    list: () => HttpClient.get(API_ENDPOINTS.INVOICES),
    fetch: (invoiceId) => HttpClient.get(API_ENDPOINTS.INVOICE_FETCH(invoiceId)),
    listKPI: () => HttpClient.get(API_ENDPOINTS.INVOICE_KPI),

    delete: (input) => HttpClient.post(API_ENDPOINTS.DELETE_INVOICE, input),
    listAdmin: () => HttpClient.get(API_ENDPOINTS.INVOICES_ADMIN),
    editAdmin: (input) =>
      HttpClient.post(API_ENDPOINTS.EDIT_INVOICES_ADMIN, input),
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATE_INVOICE, input),
    creatorList: () => HttpClient.get(API_ENDPOINTS.CREATOR_INVOICES),
    createPaypalPayment: (input) => HttpClient.post(API_ENDPOINTS.CREATE_PAYPAL, input),

  };

  companies = {
    list: () => HttpClient.get(API_ENDPOINTS.COMPANY),
    create: (input) => HttpClient.post(API_ENDPOINTS.COMPANY, input),
    delete: (input) => HttpClient.post(API_ENDPOINTS.DELETE_COMPANY, input),
    edit: (input) =>
      HttpClient.post(
        `${API_ENDPOINTS.COMPANY}/${input.companyId}`,
        input.params
      ),
    listFetch: () => HttpClient.get(API_ENDPOINTS.FETCH_COMPANY_DATA),
    listUsers: () => HttpClient.get(API_ENDPOINTS.USERS_COMPANY),
    demoRegister: (input) => HttpClient.post(API_ENDPOINTS.DEMO_REGISTER, input),

  };

  conversations = {
    create: (input) => HttpClient.post(API_ENDPOINTS.CONVERSATIONS, input),
    check: (input) => HttpClient.post(API_ENDPOINTS.CONVERSATION_CHECK, input),
    sendMessage: (input) => HttpClient.post(API_ENDPOINTS.MESSAGES, input),
    getMessages: (conversationId) => HttpClient.get(`${API_ENDPOINTS.GET_CONVERSATION_MESSAGES}/${conversationId}`),
  };

  partnerships = {
    list: () => HttpClient.get(API_ENDPOINTS.PARTNERSHIPS),
    create: (input) => HttpClient.post(API_ENDPOINTS.PARTNERSHIPS, input),
    fetchPartnership: (id) => HttpClient.get(`${API_ENDPOINTS.PARTNERSHIPS_FETCH}/${id}`),  // Corrected method
    updateStatus: (id, input) => HttpClient.put(`${API_ENDPOINTS.UPDATE_PARTNERSHIP_STATUS}/${id}/status`, input),
    delete: (id) => HttpClient.delete(`${API_ENDPOINTS.DELETE_PARTNERSHIP}/${id}`),
    getAdminDetails: () => HttpClient.get(API_ENDPOINTS.ADMIN_PARTNERSHIPS),
    getDeliverables: (id, creatorId) => HttpClient.get(API_ENDPOINTS.PARTNERSHIP_DELIVERABLES(id, creatorId)),
    bookPublic: (input) => HttpClient.post(API_ENDPOINTS.PARTNERSHIPS_PUBLIC, input),
    share: (id, input) => HttpClient.post(API_ENDPOINTS.PARTNERSHIPS_SHARE(id), input),

  };

  partnershipConversations = {
    create: (input) => HttpClient.post(API_ENDPOINTS.PARTNERSHIP_CONVERSATIONS, input),
    check: (input) => HttpClient.post(API_ENDPOINTS.PARTNERSHIP_CONVERSATION_CHECK, input),
    sendMessage: (input) => HttpClient.post(API_ENDPOINTS.PARTNERSHIP_MESSAGES, input),
    getMessages: (conversationId) => HttpClient.get(`${API_ENDPOINTS.GET_PARTNERSHIP_CONVERSATION_MESSAGES}/${conversationId}`),
  };
  creatorPartnershipConversations = {
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_PARTNERSHIP_CONVERSATIONS, input),
    check: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_PARTNERSHIP_CONVERSATION_CHECK, input),
    sendMessage: (input) => HttpClient.post(API_ENDPOINTS.CREATOR_PARTNERSHIP_MESSAGES, input),
    getMessages: (conversationId) => HttpClient.get(`${API_ENDPOINTS.GET_CREATOR_PARTNERSHIP_CONVERSATION_MESSAGES}/${conversationId}`),
  };

  commissions = {
    list: () => HttpClient.get(API_ENDPOINTS.COMMISSIONS),
    getAll: () => HttpClient.get(API_ENDPOINTS.COMMISSIONS_All),
  };

  deliverables = {
    list: () => HttpClient.get(API_ENDPOINTS.DELIVERABLES),
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATE_DELIVERABLES, input),
    update: (id, input) => HttpClient.put(`${API_ENDPOINTS.UPDATE_DELIVERABLES}/${id}`, input), // Updated
    getByPartnership: (partnershipId) => HttpClient.get(`${API_ENDPOINTS.GET_DELIVERABLE_PARTNERSHIPS}/${partnershipId}`),
  };

  miscProjects = {
    list: () => HttpClient.get(API_ENDPOINTS.MISC_PROJECTS),
    create: (input) => HttpClient.post(API_ENDPOINTS.MISC_PROJECTS_CREATE, input),
    // fetch: (id) => HttpClient.get(API_ENDPOINTS.PARTNERSHIPS_FETCH(id)),
    // updateStatus: (id, input) => HttpClient.put(`${API_ENDPOINTS.UPDATE_PARTNERSHIP_STATUS}/${id}/status`, input),
    // delete: (id) => HttpClient.delete(`${API_ENDPOINTS.DELETE_PARTNERSHIP}/${id}`),
  };

  files = {
    post: (input) => HttpClient.post(API_ENDPOINTS.POST_FILES, input),
    fetch: () => HttpClient.get(API_ENDPOINTS.FETCH_FILES),
    createAsset: (input) => HttpClient.post(API_ENDPOINTS.CREATE_ASSET, input),
    post_creator_pfp: (input) => HttpClient.post(API_ENDPOINTS.post_creator_pfp, input),

  };

  trackers = {
    create: (input) => HttpClient.post(API_ENDPOINTS.CREATE_TRACKERS, input),
    fetch: () => HttpClient.get(API_ENDPOINTS.FETCH_TRACKERS),
    update: (trackerId, input) => HttpClient.put(`${API_ENDPOINTS.UPDATE_TRACKERS}/${trackerId}`, input),
    exportSheet: (input) => HttpClient.post(API_ENDPOINTS.EXPORT_SHEET_TRACKER, input),

  };

  agency = {
    getCreator: async (creatorId) => {
      const data = await HttpClient.get(API_ENDPOINTS.AGENCY_CREATOR(creatorId));
      return {
        ...data,
        campaigns: data.campaigns.map((campaign) => ({ ...campaign, ideal_duedate: new Date(campaign.ideal_duedate) })),
        conversations: data.conversations.map((conversation) => ({ ...conversation, created_at: new Date(conversation.created_at) })),
        partnerships: data.partnerships.map((partnership) => (
          { ...partnership, 
            finish_date: partnership.finish_date ? new Date(partnership.finish_date) : null,
            paid_date: partnership.paid_date ? new Date(partnership.paid_date) : null,
            proposal_date: partnership.proposal_date ? new Date(partnership.proposal_date) : null,
            invoice_date: partnership.invoice_date ? new Date(partnership.invoice_date) : null
          }))
      }
    },
    getConversation: async (conversationId) => {
      const data = await HttpClient.get(API_ENDPOINTS.AGENCY_CONVERSATION(conversationId));
      return data.map((message) => ({ 
        ...message, 
        created_at: message.created_at ? new Date(message.created_at) : null,
        read_at: message.read_at ? new Date(message.read_at) : null
      }))
    },
    sendMessage: (conversationId, input) => HttpClient.post(API_ENDPOINTS.AGENCY_CONVERSATION(conversationId), input),
    editCreator: (creatorId, input) => HttpClient.put(API_ENDPOINTS.AGENCY_CREATOR(creatorId), input),
    campaignAccept: (campaignId, input) => HttpClient.post(API_ENDPOINTS.AGENCY_CAMPAIGN_CREATOR_ACCEPT(campaignId), input),
    campaignLiveLink: (campaignId, input) => HttpClient.post(API_ENDPOINTS.AGENCY_CAMPAIGN_CREATOR_LIVELINK(campaignId), input)
  };


  social = {
    follow: (data) => HttpClient.post(API_ENDPOINTS.SOCIAL_FOLLOW, data),
    unfollow: (username) => HttpClient.delete(`${API_ENDPOINTS.SOCIAL_UNFOLLOW}/${username}`),
    getFollowers: (username) => HttpClient.get(`${API_ENDPOINTS.FETCH_FOLLOWERS}/${username}`),
    getFollowing: (username) => HttpClient.get(`${API_ENDPOINTS.FETCH_FOLLOWING}/${username}`),
    isFollowing: (username) => HttpClient.get(`${API_ENDPOINTS.SOCIAL_IS_FOLLOWING}/${username}`),
  };

  emailCampaigns = {
    create: (input) => HttpClient.post(API_ENDPOINTS.INTERNAL_EMAIL_CAMPAIGNS_CREATE, input),
    fetch: () => HttpClient.get(API_ENDPOINTS.INTERNAL_EMAIL_CAMPAIGNS),
  };

   castingCalls = {
    list: () => HttpClient.get(API_ENDPOINTS.CASTING_CALLS_LIST),
    updateStatus: (id, status) => HttpClient.put(`${API_ENDPOINTS.CASTING_CALLS_UPDATE_STATUS}/${id}`, { status }),
    postNew: (input) => HttpClient.post(API_ENDPOINTS.CASTING_CALLS_POST_NEW, input),
    creatorFetch: () => HttpClient.get(API_ENDPOINTS.creatorCastingCalls),
    creatorApply: (id) => HttpClient.post(`${API_ENDPOINTS.CASTING_CREATOR_APPLY}/${id}`),
    getById: (id) => HttpClient.get(API_ENDPOINTS.CASTING_CALL_SPEC(id)),

  };  
  
  blocklist = {
    list: () => HttpClient.get(API_ENDPOINTS.BLOCKLIST),
    create: (input) => HttpClient.post(API_ENDPOINTS.BLOCKLIST, input),
    delete: (mail) => HttpClient.delete(API_ENDPOINTS.BLOCKED_CONTACT(mail)),
  };

  collections = {
    list: () => HttpClient.get(API_ENDPOINTS.collections),
    create: (input) => HttpClient.post(API_ENDPOINTS.collections_post, input),
    delete: (input) => HttpClient.delete(API_ENDPOINTS.collections_delete(input)),
  };

}

export default new Client();
