import React, { useState } from "react";
import {
  Container,
  Button,
  TextField,
  Toolbar,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom"; // Also import useLocation
import routes from "../../Config/routes.js";
import ConsentForm from "./popups/consentform.js";
import { useGoogleLogin } from '@react-oauth/google';
import BlitzHeader from "../../Components/BlitzHeader.js";
import { useMutation, useQuery } from "react-query";
import API from "../../API/index.js";
import AlertDialog from "../../Components/AlertDialog.js";
import useAlertDialog from "../../Components/useAlertDialog.js";

const CreatorConnectReatuh = () => {
  const navigate = useNavigate();
  const { creator } = useParams();
  const [consentOpen, setConsentOpen] = useState(false);
  const { mutate, isLoading } = useMutation({ mutationFn: API.creatorConnect.reauth, mutationKey: ['creatorConnect', 'reauth'] });
  const [waitGoogle, setWaitGoogle] = useState(false);
  const { data, isLoading: reauthDataLoading } = useQuery({
    queryKey: ['reauth', creator],
    queryFn: () => API.creatorConnect.getReauth(creator),
    onError: (error) => {
      alert(`There was an error fetching this reauth request: ${error?.response?.data?.error ? error.response.data.error : error.message}`);
      navigate(routes.home);
    },
    onSuccess: (data) => console.log(data),
    refetchInterval: false,
    refetchOnWindowFocus: false
  })

  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const [googleRequest, setGoogleRequest] = useState('')

  const scope = [
    'https://www.googleapis.com/auth/gmail.modify',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email'
  ];

  const scopeString = scope.reduce((accum, currSco, currIdx) => (accum + (currIdx == 0 ? '' : ' ') + currSco), '');

  const googleLogin = useGoogleLogin({
    onError: errorResponse => {
      alert(`Error getting Google authorization: ${errorResponse}`);
      setWaitGoogle(false);
    },
    onNonOAuthError: (error) => {
      setWaitGoogle(false);
      alert(`Error getting Google authorization: ${error}`);
    },
    hint: data?.email ? data.email : undefined,
    scope: scopeString,
    flow: "auth-code",
    ux_mode: 'redirect',
    redirect_uri: 'https://blitzpay.pro/creatorconnect/redirect',
    state: googleRequest
  });

  useEffect(() => {
    if (googleRequest) {
      googleLogin();
    }
  }, [googleRequest]);

  const handleConsentClose = (consented) => {
    setConsentOpen(false);
    if (consented) {
      mutate({ google_code, username: creator }, {
        onSuccess: (data) => {
          setGoogleRequest(data.request_id);
        },
        onError: (error) => {
          openDialog("Error", 
            `There was an error reauthorizating your Google account: ${error?.response?.data?.error ? error?.response?.data?.error : error.message}`,
          closeDialog, closeDialog, 'Ok', null);
        }
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading || waitGoogle || reauthDataLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BlitzHeader>Authorize</BlitzHeader>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Toolbar></Toolbar>
      <Container maxWidth="md" sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', paddingBlockStart: 2 }}>
        <Typography variant="h4">Reauthorization for Partners</Typography>
        <TextField label="Creator Name" fullWidth value={data?.username || '...'} disabled />
        <Button variant="contained" color="primary" onClick={() => setConsentOpen(true)}>
          Reauthorize my Google Account
        </Button>
      </Container>

      <ConsentForm open={consentOpen} onClose={handleConsentClose} />
    </>
  );
};

export default CreatorConnectReatuh;
