import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Card, CardContent, CardMedia, Typography, CircularProgress, Box } from '@mui/material';
import blitzLogo from "../../Components/globalAssets/platty.png";
import API from '../../API';
import { StyleContent } from '../../Utils/ThemedStyles';

const PartnerCreatorRoster = () => {
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCreators = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.creators.listPartners;
        setCreators(response.creators || []);
      } catch (err) {
        setError('Failed to load creators: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

   
      fetchCreators();
  
  },);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid container sx={{ ...StyleContent, padding: 3 }} spacing={4}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h3" align="center">
            OUR PARTNER CREATORS
          </Typography>
        </Grid>

        {creators.length > 0 ? (
          creators.map((creator) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={creator.creator}>
              <Card sx={{ maxWidth: 345, margin: 'auto', boxShadow: 3 }}>
                <CardMedia
                  sx={{ height: 200 }}
                  image={creator.pfphref || blitzLogo}
                  title={`${creator.creator}'s profile image`}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {creator.creator}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Platform: {creator.tiktok_following || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Followers: {creator.tiktok || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    <a href={creator.platform_link} target="_blank" rel="noopener noreferrer">
                      View Profile
                    </a>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              No creators available for this partner.
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PartnerCreatorRoster;
