import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Badge,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import API from '../../../API';
import { useNavigate } from 'react-router-dom';
import CreatorCampaignPitchedDialog from './creatorcomponents/creatorCampaignPitchedDialog';
import PartnershipConversationDialog from './partnershipcomps/creatorpartnershipconversationdialog';
import { getOfferFromCampaign } from '../../../Utils/constants';

const CheckIsPitched = (campaign, username) => {
  const creator = campaign.creators.find(c => c.id === username);
  return (
    (creator?.status === undefined || creator?.status === 'Pitched') &&
    campaign.campaign_status === 'Launched'
  );
};

const CreatorCollaborations = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;
  const token = creatorToken?.token;

  const [collaborations, setCollaborations] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('All');
  const [currentCampaign, setCurrentCampaign] = useState(-1);
  const [currentPartnership, setCurrentPartnership] = useState(null);
  const [openPitch, setOpenPitch] = useState(false);
  const [openPartnershipDialog, setOpenPartnershipDialog] = useState(false);

  const navigate = useNavigate();

  const fetchCampaigns = async () => {
    if (!token) return [];
    return API.creatorConnect.campaigns(token);
  };

  const fetchPartnerships = async () => {
    return API.creatorConnect.getPartnerships();
  };

  const {
    isLoading: campaignsLoading,
    error: campaignsError,
    data: campaigns,
    refetch: refetchCampaigns,
  } = useQuery(['campaigns', 'creator', username], fetchCampaigns, {
    initialData: [],
    keepPreviousData: false,
  });

  const {
    isLoading: partnershipsLoading,
    error: partnershipsError,
    data: partnerships,
    refetch: refetchPartnerships,
  } = useQuery(['creator', 'partnerships'], fetchPartnerships, {
    initialData: [],
    keepPreviousData: false,
  });

  useEffect(() => {
    if (campaigns && partnerships) {
      const campaignsWithType = campaigns.map(campaign => ({ ...campaign, type: 'campaign' }));
      const partnershipsWithType = partnerships.map(partnership => ({
        ...partnership,
        type: 'partnership',
      }));
      const combinedCollaborations = [...campaignsWithType, ...partnershipsWithType];
      setCollaborations(combinedCollaborations);
    }
  }, [campaigns, partnerships]);

  useEffect(() => {
    if (!openPitch) {
      refetchCampaigns();
    }
  }, [openPitch, refetchCampaigns]);

  useEffect(() => {
    if (!openPartnershipDialog) {
      refetchPartnerships();
    }
  }, [openPartnershipDialog, refetchPartnerships]);

  const loading = campaignsLoading || partnershipsLoading;
  const error = campaignsError || partnershipsError;

  const filteredCollaborations = useMemo(() => {
    return collaborations.filter(item => {
      let isValidSelection = true;

      if (item.type === 'campaign') {
        const creator = item.creators.find(c => c.id === username);
        let isAcceptedCreator =
          creator?.status !== undefined &&
          creator.status !== 'Declined' &&
          creator.status !== 'Dropped' &&
          creator.status !== 'Pitched';

        switch (currentFilter) {
          case 'All':
            isValidSelection =
              item.campaign_status !== 'Archived' &&
              item.campaign_status !== 'Draft' &&
              item.campaign_status !== 'DELETED';
            break;
          case 'Current':
            isValidSelection = isAcceptedCreator && item.campaign_status === 'Launched';
            break;
          case 'Offered':
            isValidSelection =
              (creator?.status === undefined || creator?.status === 'Pitched') &&
              item.campaign_status === 'Launched';
            break;
          case 'Completed':
            isValidSelection = isAcceptedCreator && item.campaign_status === 'Completed';
            break;
          default:
            isValidSelection = true;
        }
      } else if (item.type === 'partnership') {
        switch (currentFilter) {
          case 'All':
            isValidSelection =
              item.status !== 'Archived' && item.status !== 'Draft' && item.status !== 'DELETED';
            break;
          case 'Current':
            isValidSelection = item.status === 'Active';
            break;
          case 'Offered':
            isValidSelection = item.status === 'pending' || item.status === 'Draft';
            break;
          case 'Completed':
            isValidSelection = item.status === 'Completed';
            break;
          default:
            isValidSelection = true;
        }
      }

      return isValidSelection;
    });
  }, [collaborations, currentFilter, username]);

  // Prioritize pending offers for partnerships in sorting
  const sortedCollaborations = useMemo(() => {
    return filteredCollaborations.slice().sort((a, b) => {
      const getPriority = item => {
        if (item.type === 'partnership') {
          if (item.status === 'pending' || item.status === 'Draft') return 1;
          return 2;
        }
        return 3;
      };
      const priorityA = getPriority(a);
      const priorityB = getPriority(b);
      if (priorityA !== priorityB) return priorityA - priorityB;
      return 0;
    });
  }, [filteredCollaborations]);

  const pitchedCount = useMemo(() => {
    return collaborations.reduce((amount, item) => {
      let isOffered = false;

      if (item.type === 'campaign') {
        const creator = item.creators.find(c => c.id === username);
        isOffered =
          (creator?.status === undefined || creator?.status === 'Pitched') &&
          item.campaign_status === 'Launched';
      } else if (item.type === 'partnership') {
        isOffered = item.status === 'pending' || item.status === 'Draft';
      }

      if (isOffered) amount++;
      return amount;
    }, 0);
  }, [collaborations, username]);

  const handleItemClick = item => {
    if (item.type === 'campaign') {
      const isPitch = CheckIsPitched(item, username);
      if (isPitch) {
        setCurrentCampaign(item.id);
        setOpenPitch(true);
      } else {
        navigate(`/creatorconnect/campaigns/${item.id}`);
      }
    } else if (item.type === 'partnership') {
      navigate(`/creatorconnect/partnerships/${item.id}`);
    }
  };

  const handleClosePitch = () => {
    setOpenPitch(false);
    setCurrentCampaign(-1);
  };

  const handleClosePartnershipDialog = () => {
    setOpenPartnershipDialog(false);
    setCurrentPartnership(null);
  };

  const getButtonLabel = item => {
    if (item.type === 'campaign') {
      if (currentFilter !== 'Offered') {
        return 'Manage Campaign';
      } else {
        return 'See Pitch';
      }
    } else if (item.type === 'partnership') {
      return 'Manage Partnership';
    }
  };

  if (loading)
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Typography sx={{ color: 'error' }}>
        Error fetching collaborations: {error.message}
      </Typography>
    );

  return (
    <Box>
      <Paper elevation={2} square>
        <Tabs
          value={currentFilter}
          onChange={(event, value) => setCurrentFilter(value)}
          centered
        >
          <Tab label="All" value="All" />
          <Tab label="Current" value="Current" />
          <Tab
            label={
              <Badge
                badgeContent={pitchedCount}
                color="secondary"
                overlap="rectangular"
                sx={{ padding: 1 }}
              >
                Offered
              </Badge>
            }
            value="Offered"
          />
          <Tab label="Completed" value="Completed" />
        </Tabs>
      </Paper>
      <Typography variant="h6" gutterBottom sx={{ p: 2, marginBlock: 1 }}>
        Your Collaborations
      </Typography>
      <Grid container spacing={3} sx={{ paddingInline: { xs: 1, md: 4 } }}>
        {sortedCollaborations && sortedCollaborations.length > 0 ? (
          sortedCollaborations.map(item => (
            <Grid item xs={12} sm={6} md={3} key={`${item.type}-${item.id}`}>
              <Card>
                <CardHeader
                  title={item.name}
                  titleTypographyProps={{ sx: { textTransform: 'capitalize' } }}
                  subheader={item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                />
                <CardContent>
                  {item.type === 'campaign' && (
                    <>
                      <Typography variant="body2">
                        Offer: {getOfferFromCampaign(item, username)}
                      </Typography>
                      <Typography variant="body2">Status: {item.campaign_status}</Typography>
                    </>
                  )}
                  {item.type === 'partnership' && (
                    <>
                      <Typography color="textSecondary">{item.description}</Typography>
                      <Typography color="textSecondary">Status: {item.status}</Typography>
                    </>
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleItemClick(item)}
                  >
                    {getButtonLabel(item)}
                  </Button>
                  <IconButton onClick={() => {/* handle share */ }}>
                    <ShareIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography align="center">No collaborations available</Typography>
          </Grid>
        )}
      </Grid>
      <CreatorCampaignPitchedDialog
        openDialog={openPitch}
        handleCloseDialog={handleClosePitch}
        campaignId={currentCampaign}
      />
      {currentPartnership && (
        <PartnershipConversationDialog
          openDialog={openPartnershipDialog}
          handleCloseDialog={handleClosePartnershipDialog}
          partnership={currentPartnership}
        />
      )}
    </Box>
  );
};

export default CreatorCollaborations;
