import { createSvgIcon } from "@mui/material";

const YoutubeIcon = createSvgIcon(
    <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.1674 20H5.16574C2.48946 20 0.333252 17.6331 0.333252 14.7219V5.27811C0.333252 2.35503 2.50029 0 5.16574 0H25.1674C27.8437 0 29.9999 2.36686 29.9999 5.27811V14.7219C30.0107 17.645 27.8437 20 25.1674 20Z" fill="currentColor" />
        <path d="M20.4142 9.85207L12 5V14.7041L20.4142 9.85207Z" fill="white" />
    </svg>
    ,
    'Youtube',
);

export default YoutubeIcon