import React, { useState } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Button, Box, Link } from "@mui/material";
import profilePhoto from "../../../../Components/globalAssets/ppfLogo.png"; // Placeholder for the profile photo

const CampaignsContainers = ({ creators, handleStatusChange }) => {
  const getBackgroundColor = (status) => {
    switch (status) {
      case "Dropped":
        return {backgroundColor: "error.main", color: "error.contrastText"};
      case "Approved":
        return {backgroundColor: "success.main", color: "success.contrastText"};
      case "Counter":
        return {backgroundColor: "warning.main", color: "warning.contrastText"};
      default:
        return {};
    }
  };

  return (
    <Grid container spacing={2}>
      {creators.map((creator, index) => {
        const cardColor = getBackgroundColor(creator.status);

        const handleStatusButtonClick = (status) => {
          handleStatusChange(creator.id, status);
        };

        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card sx={cardColor} elevation={1}>
              <CardMedia
                component="img"
                height="250"
                image={creator.pfphref || profilePhoto}
                alt={creator.name}
                sx={{ objectFit: "cover" }}
              />
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  <Link
                    href={`https://blitzpay.pro/creators/${creator.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline='hover'
                  >
                    @{creator.name}
                  </Link>
                </Typography>
                <Typography variant="body1">Following: {creator.following}</Typography>
                <Typography variant="body1" style={{ marginTop: 10 }}>{`Recommended Price: $${creator.price} USD`}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href={`https://blitzpay.pro/creators/${creator.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "block", marginTop: 8 }}
                >
                  View Media Kit
                </Button>
                <Box display="flex" justifyContent="space-between" mt={2} gap={1}>
                  <Button
                    variant="contained"
                    color='error'
                    sx={{
                      flex: 1,
                    }}
                    onClick={() => handleStatusButtonClick("Dropped")}
                  >
                    Drop
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      flex: 1,
                    }}
                    color='success'
                    onClick={() => handleStatusButtonClick("Approved")}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color='warning'
                    sx={{
                      flex: 1,
                    }}
                    onClick={() => handleStatusButtonClick("Counter")}
                  >
                    Negotiate
                  </Button>
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <Link
                    href={creator.platformLink ?? ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on {creator.promotionPlatform ?? ""}
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CampaignsContainers;