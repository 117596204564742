import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import client from "../../../../API";
import { useMutation } from "react-query";
import InvitedCreators from "./invitingcastingcalls";

const CastingCallDialog = ({ open, onClose, castingCallId }) => {
  const [castingCall, setCastingCall] = useState(null);
  const [tabValue, setTabValue] = useState(0); // For tab navigation

  const { mutate: fetchCastingCall } = useMutation(
    () => client.castingCalls.getById(castingCallId),
    {
      onSuccess: (data) => setCastingCall(data),
      onError: (error) => console.error("Error fetching casting call:", error),
    }
  );

  useEffect(() => {
    if (castingCallId) {
      fetchCastingCall();
    }
  }, [castingCallId]);

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Casting Call Details</DialogTitle>
      <DialogContent>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Overview" />
          <Tab label="Applications" />
          <Tab label="Invited Creators" />
        </Tabs>
        <Box mt={2}>
          {tabValue === 0 && castingCall && (
            <>
              <Typography variant="h6">
                Platforms: {castingCall.platforms ? castingCall.platforms.join(", ") : "N/A"}
              </Typography>
              <Typography variant="body1">Brief: {castingCall.brief || "N/A"}</Typography>
              <Typography variant="body1">
                Budget: ${castingCall.budgetMin || 0} - ${castingCall.budgetMax || 0}
              </Typography>
              <Typography variant="body1">
                Promotion Types: {castingCall.promotionTypes ? castingCall.promotionTypes.join(", ") : "N/A"}
              </Typography>
            </>
          )}
          {tabValue === 1 && castingCall && (
            <Box>
              <Typography variant="h6">Applications</Typography>
              {castingCall.applications && castingCall.applications.length ? (
                castingCall.applications.map((application, index) => (
                  <Typography key={index}>{application.name}</Typography>
                ))
              ) : (
                <Typography>No applications yet.</Typography>
              )}
            </Box>
          )}
          {tabValue === 2 && castingCall && (
            <InvitedCreators invitedCreators={castingCall.invited_creators || []} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CastingCallDialog;
