import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RateField } from '../../Components/RateField';

const BookingDialog = ({ open, onClose, submitBooking, creator, promotionData }) => {
  const [formData, setFormData] = useState({
    selectedPromotion: '',
    rate: 50,
    selectedDate: new Date().toISOString().substring(0, 10),
    details: '',
    email: '',
    partnershipName: '',
    creatorId: creator?.creator || ''
  });

  const [warning, setWarning] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSliderChange = (event, newValue) => {
    setFormData(prevState => ({ ...prevState, rate: newValue }));
  };

  const handlePromoChange = (value) => {
    setFormData(prevState => ({ ...prevState, selectedPromotion: value, rate: creator[value] }));
  };

  const minRate = formData.selectedPromotion ? creator[formData.selectedPromotion] : 50.0;

  useEffect(() => {
    if (formData.rate < minRate) {
      setWarning(`Warning: Offers below the creator's asking range ($${minRate}) are usually rejected.`);
    } else {
      setWarning('');
    }
  }, [formData, minRate]);

  useEffect(() => {
    if (creator) {
      setFormData({
        selectedPromotion: '',
        rate: 50,
        selectedDate: new Date().toISOString().substring(0, 10),
        details: '',
        email: '',
        partnershipName: '',
        creatorId: creator.creator
      });
    }
  }, [creator]);

  const handleSubmit = async () => {
    // Ensure all fields are filled
    const { selectedPromotion, rate, selectedDate, details, email, partnershipName } = formData;
    if (!selectedPromotion || !rate || !selectedDate || !details || !email || !partnershipName) {
      alert('All fields are required.');
      return;
    }

    setLoading(true);
    const success = await submitBooking({
      ...formData,
      notes: formData.details
    });
    setLoading(false);
    if (success) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Book a Promotion
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Chat with this top creator directly</Typography>
        <Typography variant="subtitle1">Instructions will be emailed to you.</Typography>
        <h2>How to Make a Booking</h2>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/Uzl1iZx56Ng?si=hMiRLUVmKaKHmI3d"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />

        <TextField
          label="Partnership Name"
          fullWidth
          name="partnershipName"
          value={formData.partnershipName}
          onChange={handleChange}
          margin="dense"
          required
        />
        <TextField
          select
          label="Promotion Type"
          fullWidth
          name="selectedPromotion"
          value={formData.selectedPromotion}
          onChange={(e) => handlePromoChange(e.target.value)}
          margin="dense"
          required
        >
          {promotionData.map((promo) => (
            <MenuItem key={`promoselect-${promo.rateKey}`} value={promo.rateKey}>
              {promo.label}
            </MenuItem>
          ))}
        </TextField>
        <Typography gutterBottom>
          Your Budget: ${formData.rate}
        </Typography>
        <RateField
          error={!!warning}
          helperText={warning}
          label="Budget"
          variant="outlined"
          minValue={minRate / 2}
          fullWidth
          value={formData.rate}
          onChange={(value) =>
            handleSliderChange(null, value)
          }
          sx={{ marginTop: 1, marginBottom: 2 }}
        />
        <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
          Each creator partnership requires a budget that fits within the creator's rate range. You are more than welcome to negotiate directly with the creator once you claim your Blitz account. Upon booking, a signup link will be emailed to you.
        </Typography>

        <TextField
          label="Email"
          type="email"
          fullWidth
          name="email"
          value={formData.email}
          onChange={handleChange}
          margin="dense"
          required
        />
        <TextField
          id="date-picker"
          label="Select Date"
          type="date"
          fullWidth
          variant="outlined"
          name="selectedDate"
          value={formData.selectedDate}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          margin="dense"
          required
        />
        <TextField
          label="Details"
          fullWidth
          multiline
          rows={4}
          name="details"
          value={formData.details}
          onChange={handleChange}
          margin="dense"
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDialog;
