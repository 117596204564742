import React, { useState, useEffect, useMemo, memo } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Pagination,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Link,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import client from "../../../API";
import CreatorDialog from "./creatorintake";
import CreatorContainers from "./creatorcontainersearch";
import PromoPlatFilter from "./Filters/PromoPlatFilter";
import NichesFilter from "./Filters/NichesFilters";
import CreatorFilter from "./Filters/CreatorFilters";
import DemoFilters from "./Filters/DemoFilters";
import MinMaxFilter from "./Filters/MinMaxFilter";
import {
  headerToKeyMap,
  indexToPromoFilter,
  platformToKey,
  platPromoToKey,
  platPromoToHead,
  headers
} from "../../../Utils/constants";
import {
  FilterAltOffRounded,
  Delete as DeleteIcon,
  PersonAddAlt1Outlined as PersonAddAlt1OutlinedIcon,
} from "@mui/icons-material";
import { StyledTableRow } from "../../../Utils/styledcell";

const StyleContent = {
  overflowX: "auto",
  paddingLeft: 2,
  marginBlockEnd: 2,
  width: "100%",
};

const StyleCsvSection = {
  overflowX: "auto",
  marginInline: 1,
  marginBlockEnd: 1,
};

const classes = {
  csvTable: {
    width: "100%",
    tableLayout: "auto",
  },
  linkCell: {
    maxWidth: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const LeftColumnSearch = memo(function LeftColumnSearch({
  onCreatorSelect,
  selectedItems,
  data,
  loading,
  onInviteToPartnership = null,
  itemsPerPage = 18,
}) {
  const [isCreatorDialogOpen, setIsCreatorDialogOpen] = useState(false);
  const [showPretty, setShowPretty] = useState(true);
  const [page, setPage] = useState(0);

  // New filters
  const [promoIndex, setPromoIndex] = useState(1);

  const [nicheSet, setNicheSet] = useState(new Set([]));

  const [creatorGender, setCreatorGender] = useState(new Set([]));
  const [creatorRace, setCreatorRace] = useState(new Set([]));
  const [creatorRegion, setCreatorRegion] = useState(new Set([]));

  const [publicGender, setPublicGender] = useState(new Set([]));
  const [publicAge, setPublicAge] = useState(new Set([]));
  const [publicCountry, setPublicCountry] = useState(new Set([]));

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [minFollow, setMinFollow] = useState(null);
  const [maxFollow, setMaxFollow] = useState(null);

  const [minViews, setMinViews] = useState(null);
  const [maxViews, setMaxViews] = useState(null);

  const [filter, setFilter] = useState("");

  const [search, setSearch] = useState("");

  // Collection management
  const [selectedCollection, setSelectedCollection] = useState("");
  const [isAddCollectionDialogOpen, setIsAddCollectionDialogOpen] =
    useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");

  // Fetch collections
  const { data: collections = [], refetch: fetchCollections } = useQuery(
    "collections",
    () => client.collections.list(),
    { initialData: [] }
  );

  // Mutation to create a new collection
  const { mutate: createCollection } = useMutation(client.collections.create, {
    onSuccess: () => {
      fetchCollections();
      setNewCollectionName("");
    },
  });

  // Mutation to delete a collection
  const { mutate: deleteCollection } = useMutation(client.collections.delete, {
    onSuccess: (_, id) => {
      fetchCollections();
      if (selectedCollection === id) {
        setSelectedCollection("");
      }
    },
  });

  // Handler to save a new collection
  const handleSaveCollection = () => {
    const filters = {
      promoIndex,
      nicheSet: Array.from(nicheSet),
      creatorGender: Array.from(creatorGender),
      creatorRace: Array.from(creatorRace),
      creatorRegion: Array.from(creatorRegion),
      publicGender: Array.from(publicGender),
      publicAge: Array.from(publicAge),
      publicCountry: Array.from(publicCountry),
      minPrice,
      maxPrice,
      minFollow,
      maxFollow,
      minViews,
      maxViews,
    };

    createCollection({ name: newCollectionName, filters });
  };

  // Handler to delete a collection
  const handleDeleteCollection = (id) => {
    deleteCollection(id);
  };

  const handleMinMaxApply = (value, minSetter, maxSetter) => {
    minSetter(value.min);
    maxSetter(value.max);
  };

  const handleCreatorDemoApply = (value) => {
    setCreatorGender(value.gender);
    setCreatorRace(value.race);
    setCreatorRegion(value.region);
  };

  const handlePublicDemoApply = (value) => {
    setPublicGender(value.gender);
    setPublicAge(value.age);
    setPublicCountry(value.country);
  };

  const handleResetFilters = () => {
    setPromoIndex(1);

    setNicheSet(new Set([]));

    setCreatorGender(new Set([]));
    setCreatorRace(new Set([]));
    setCreatorRegion(new Set([]));

    setPublicGender(new Set([]));
    setPublicAge(new Set([]));
    setPublicCountry(new Set([]));

    setMinPrice(null);
    setMaxPrice(null);

    setMinFollow(null);
    setMaxFollow(null);

    setMinViews(null);
    setMaxViews(null);
    setSearch("");
    setFilter("");
  };

  // Debounce search input
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setFilter(search);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [search]);

  useEffect(() => {
    setPage(0);
  }, [
    promoIndex,
    filter,
    nicheSet,
    minViews,
    maxViews,
    minPrice,
    maxPrice,
    minFollow,
    maxFollow,
    publicCountry,
    publicAge,
    publicGender,
    creatorRegion,
    creatorRace,
    creatorGender,
  ]);

  const { platform, promo: promotionType } = indexToPromoFilter(promoIndex);

  const platformHeaders = [...headers[platform]];
  const promoHeader = platPromoToHead[platform][promotionType];
  //console.log(platform, promotionType);

  if (promoHeader) {
    if (platformHeaders.length >= 2) {
      platformHeaders.splice(-2, 0, promoHeader);
    } else {
      platformHeaders.push(promoHeader);
    }
  }

  const handleCreatorSubmit = (formData) => {
    console.log(formData);
    setIsCreatorDialogOpen(false);
  };

  const handleInviteToPartnership = (creator) => {
    const relevantData = {
      ...creator,
      id: creator.creator,
      name: creator.creator,
    };

    onInviteToPartnership(relevantData);
  };

  const handleSelectItem = (creatorId) => {
    const selectedCreator = data.find(
      (creator) => creator.creator === creatorId
    );
    if (!selectedCreator) {
      console.warn(`Item with ID ${creatorId} not found.`);
      return;
    }

    let priceKey = platPromoToKey[platform]?.[promotionType];
    if (!priceKey) {
      console.warn(
        `Promotion type or platform key is not available for ${platform} - ${promotionType}`
      );
      return;
    }

    const price = selectedCreator[priceKey]
      ? selectedCreator[priceKey] * 1.2
      : null;

    const platformLinkKey = `${platform} Link`;
    const platformLink = selectedCreator[headerToKeyMap[platformLinkKey]];
    const followingCount = selectedCreator[platformToKey[platform]?.[0]];

    const relevantData = {
      id: selectedCreator.creator,
      name: selectedCreator.creator,
      price: price,
      following: followingCount,
      promotionPlatform: platform,
      promotionType: promotionType,
      platformLink: platformLink,
      pfphref: selectedCreator.pfphref,
    };

    if (onCreatorSelect) {
      onCreatorSelect(creatorId, relevantData);
    }
  };

  const parseFollowerCount = (followerVar) => {
    if (!followerVar || followerVar === "N/A") return 0;
    if(Number.isFinite(followerVar))
      return followerVar;
    return parseInt(followerVar.replace(/,/g, ""), 10) || 0;
  };

  const followerRangeMatch = (followerCount) => {
    return followerCount >= minFollow && followerCount <= maxFollow;
  };

  const calculateCPM = (rate, avgViews) => {
    if (!rate || !avgViews || rate === "N/A" || avgViews === "N/A") {
      return null;
    }
    const numericalRate = rate;
    const numericalViews = parseInt(avgViews.replace(/[^0-9]/g, ""), 10);

    if (isNaN(numericalRate) || isNaN(numericalViews)) {
      return null;
    }
    return (numericalRate / numericalViews) * 1000;
  };

  const priceRangeMatch = (price) => {
    if (!price) return false;
    const numericalPrice = price;
    return numericalPrice >= minPrice && numericalPrice <= maxPrice;
  };

  const emptyCountry = { code: "N/A", value: Number.NEGATIVE_INFINITY };
  const emptyAge = { age_range: "N/A", value: Number.NEGATIVE_INFINITY };
  const emptyGender = { name: "N/A", value: Number.NEGATIVE_INFINITY };

  const highestValueCountry = (publicData) => {
    return publicData.reduce((max, demo) => {
      const demoMax = demo.country_data.reduce((ctrMax, country) => {
        if (country.value > ctrMax.value) ctrMax = country;
        return ctrMax;
      }, emptyCountry);
      if (demoMax.value > max.value) max = demoMax;
      return max;
    }, emptyCountry);
  };

  const higherValueAge = (publicData) => {
    const publicByAge = publicData.map((demo) =>
      demo.audience_data.reduce((acc, entry) => {
        const { age_range, value } = entry;
        const existingEntry = acc.find((item) => item.age_range === age_range);
        if (existingEntry) {
          existingEntry.value += Math.round(value);
        } else {
          acc.push({ age_range: age_range, value: Math.round(value) });
        }
        return acc;
      }, [])
    );
    return publicByAge.reduce((max, demo) => {
      const demoMax = demo.reduce((ageMax, ageGroup) => {
        if (ageGroup.value > ageMax.value) ageMax = ageGroup;
        return ageMax;
      }, emptyAge);
      if (demoMax.value > max.value) max = demoMax;
      return max;
    }, emptyAge);
  };

  const higherValueGender = (publicData) => {
    const publicByGender = publicData.map((demo) => [
      {
        name: "male",
        value: demo.audience_data.reduce((accum, item) => {
          if (item.gender.toLowerCase() === "male")
            accum += Math.round(item.value);
          return accum;
        }, 0),
      },
      {
        name: "female",
        value: demo.audience_data.reduce((accum, item) => {
          if (item.gender.toLowerCase() === "female")
            accum += Math.round(item.value);
          return accum;
        }, 0),
      },
    ]);

    return publicByGender.reduce((max, demo) => {
      const demoMax = demo.reduce((genderMax, genderGroup) => {
        if (genderGroup.value > genderMax.value) genderMax = genderGroup;
        return genderMax;
      }, emptyGender);
      if (demoMax.value > max.value) max = demoMax;
      return max;
    }, emptyGender);
  };

  const filteredData = useMemo(() => {
    const filterValue = indexToPromoFilter(promoIndex);
    if (
      !platformToKey[filterValue.platform] ||
      !platPromoToKey[filterValue.platform]
    ) {
      return [];
    }
    const filtered = data.filter((creator) => {
      const platformKeys = platformToKey[filterValue.platform];
      const isPlatformDataPresent = platformKeys.some((key) => {
        return creator[key] && creator[key] !== "N/A";
      });
      if (!isPlatformDataPresent) return false;

      const currPromotionKey =
        platPromoToKey[filterValue.platform][filterValue.promo];

      const promoTypeMatch =
        creator[currPromotionKey] && creator[currPromotionKey] !== null;

      // Creator data

      const primaryMarketMatch =
        nicheSet.size > 0 ? nicheSet.has(creator.primary_market) : true;

      const raceMatch =
        creatorRace.size > 0
          ? Array.from(creatorRace).some((key) =>
            creator.ethnicity?.includes(key)
          )
          : true;

      const genderMatch =
        creatorGender.size > 0
          ? Array.from(creatorGender).some((key) =>
            (creator.gender || []).includes(key)
          )
          : true;

      let regionMatch = true;
      if (creatorRegion.size > 0) {
        regionMatch = creatorRegion.has(creator.region);
      }

      // Range Data

      let avgViewsMatch = true;
      const parseViews = (viewsString) => {
        if (!viewsString) {
          return 0;
        }
        const parsedNumber = parseInt(viewsString.replace(/,/g, ""), 10) || 0;
        return parsedNumber;
      };

      if (minViews !== null && maxViews !== null) {
        const avgViewsValue = parseViews(creator.avg_views);
        avgViewsMatch =
          avgViewsValue >= minViews && avgViewsValue <= maxViews;
      }

      const priceMatch =
        minPrice !== null && maxPrice !== null
          ? priceRangeMatch(creator[currPromotionKey])
          : true;

      const followerCount = parseFollowerCount(
        creator[platformToKey[platform][0]]
      );
      const followersMatch =
        minFollow !== null && maxFollow !== null
          ? followerRangeMatch(followerCount)
          : true;

      // Demo Data

      let demoCountryMatch = true;
      if (publicCountry.size > 0) {
        const maxCountr = highestValueCountry(creator.public);
        demoCountryMatch = publicCountry.has(maxCountr.code);
      }

      let demoAgeMatch = true;
      if (publicAge.size > 0) {
        const maxAge = higherValueAge(creator.public);
        demoAgeMatch = publicAge.has(maxAge.age_range.toLowerCase());
      }

      let demoGenderMatch = true;
      if (publicGender.size > 0) {
        const maxGender = higherValueGender(creator.public);
        demoGenderMatch = publicGender.has(maxGender.name);
      }

      const searchMatch =
        filter === "" ||
        Object.values(creator).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(filter.toLowerCase())
        );

      return (
        promoTypeMatch &&
        primaryMarketMatch &&
        raceMatch &&
        genderMatch &&
        regionMatch &&
        avgViewsMatch &&
        followersMatch &&
        priceMatch &&
        demoCountryMatch &&
        demoAgeMatch &&
        demoGenderMatch &&
        searchMatch
      );
    });

    return filtered.map((creator) => {
      const platformKeys = platformToKey[filterValue.platform];
      const isPlatformDataPresent = platformKeys.some((key) => {
        return creator[key] && creator[key] !== "N/A";
      });

      const currPromotionKey =
        platPromoToKey[filterValue.platform][filterValue.promo];
      const rate = isPlatformDataPresent ? creator[currPromotionKey] : 0;
      const avgViews = creator.avg_views;

      const cpm = calculateCPM(rate, avgViews);

      const updatedCreator = { ...creator };
      Object.keys(platPromoToKey).forEach((plat) => {
        Object.values(platPromoToKey[plat]).forEach((key) => {
          if (updatedCreator[key]) {
            const price = updatedCreator[key];
            if (!isNaN(price)) {
              updatedCreator[key] = price * 1.2;
            }
          }
        });
      });

      return { ...updatedCreator, cpm };
    });
  }, [
    data,
    promoIndex,
    nicheSet,
    minViews,
    maxViews,
    minPrice,
    maxPrice,
    minFollow,
    maxFollow,
    publicCountry,
    publicAge,
    publicGender,
    creatorRegion,
    creatorRace,
    creatorGender,
    filter,
  ]);

  const generateCellContent = (creator, header) => {
    const key = headerToKeyMap[header];
    let content = null;
    if (key === "geolocation_gender_ethnicity")
      content = `${creator.geolocation} / ${creator.gender} / ${creator.ethnicity}`;
    else content = creator[key];
    if (header === "Creator" && content !== "\\N" && content !== "N/A") {
      return (
        <Link
          href={`https://blitzpay.pro/creators/${creator.creator}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content}
        </Link>
      );
    } else if (
      header.endsWith("Link") &&
      content !== "\\N" &&
      content !== "N/A"
    ) {
      return (
        <Link href={content} target="_blank" rel="noopener noreferrer">
          {content}
        </Link>
      );
    } else if (content === "\\N") {
      return <span>N/A</span>;
    } else if (content == null || content === undefined) {
      return <span>N/A</span>;
    }

    return content;
  };

  // Apply collection filters
  const applyCollection = (collection) => {
    if (collection && collection.filters) {
      const {
        promoIndex: colPromoIndex,
        nicheSet: colNicheSet,
        creatorGender: colCreatorGender,
        creatorRace: colCreatorRace,
        creatorRegion: colCreatorRegion,
        publicGender: colPublicGender,
        publicAge: colPublicAge,
        publicCountry: colPublicCountry,
        minPrice: colMinPrice,
        maxPrice: colMaxPrice,
        minFollow: colMinFollow,
        maxFollow: colMaxFollow,
        minViews: colMinViews,
        maxViews: colMaxViews,
      } = collection.filters;

      setPromoIndex(colPromoIndex || 1);
      setNicheSet(new Set(colNicheSet || []));
      setCreatorGender(new Set(colCreatorGender || []));
      setCreatorRace(new Set(colCreatorRace || []));
      setCreatorRegion(new Set(colCreatorRegion || []));
      setPublicGender(new Set(colPublicGender || []));
      setPublicAge(new Set(colPublicAge || []));
      setPublicCountry(new Set(colPublicCountry || []));
      setMinPrice(colMinPrice || null);
      setMaxPrice(colMaxPrice || null);
      setMinFollow(colMinFollow || null);
      setMaxFollow(colMaxFollow || null);
      setMinViews(colMinViews || null);
      setMaxViews(colMaxViews || null);
    }
  };

  useEffect(() => {
    if (selectedCollection) {
      const collection = collections.find(
        (col) => col.id === selectedCollection
      );
      applyCollection(collection);
    }
  }, [selectedCollection, collections]);

  return (
    <>
      {/* Existing filter row */}
      <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Box
          sx={{
            ...StyleContent,
            paddingInline: 1,
            paddingBlockStart: 2,
            display: "flex",
            flexDirection: "row",
            flexWrap: { xs: "nowrap", md: "wrap" },
            minWidth: { xs: "max-content", md: 'auto' },
            gap: 1,
          }}
        >
          <PromoPlatFilter value={promoIndex} onChangeValue={setPromoIndex} />
          <NichesFilter value={nicheSet} onChangeValue={setNicheSet} />
          <MinMaxFilter
            minValue={minPrice}
            maxValue={maxPrice}
            width="6rem"
            name="Rate"
            onChangeValue={(value) =>
              handleMinMaxApply(value, setMinPrice, setMaxPrice)
            }
          />
          <MinMaxFilter
            minValue={minFollow}
            maxValue={maxFollow}
            name="Followers"
            width="8rem"
            onChangeValue={(value) =>
              handleMinMaxApply(value, setMinFollow, setMaxFollow)
            }
          />
          <MinMaxFilter
            minValue={minViews}
            maxValue={maxViews}
            name="Avg. Views"
            width="10rem"
            onChangeValue={(value) =>
              handleMinMaxApply(value, setMinViews, setMaxViews)
            }
          />
          <CreatorFilter
            genderValue={creatorGender}
            raceValue={creatorRace}
            regionValue={creatorRegion}
            onChangeValue={handleCreatorDemoApply}
          />
          <DemoFilters
            genderValue={publicGender}
            ageValue={publicAge}
            countryValue={publicCountry}
            onChangeValue={handlePublicDemoApply}
          />
          <TextField
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            label="Search"
            sx={{ width: "10rem" }}
          />

          {/* Collections Dropdown moved here */}
          <TextField
            value={selectedCollection}
            onChange={(event) => {
              setSelectedCollection(event.target.value);
            }}
            label="Collections"
            sx={{ width: "10rem" }}
            SelectProps={{
              renderValue: (selected) => {
                const collection = collections.find(
                  (col) => col.id === selected
                );
                return collection ? collection.name : "None";
              }
            }}
            select>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {collections.map((collection) => (
              <MenuItem key={collection.id} value={collection.id}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>{collection.name}</Box>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteCollection(collection.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              onClick={() => {
                setIsAddCollectionDialogOpen(true);
              }}
            >
              Add New Collection
            </MenuItem>
          </TextField>
          <Box
            sx={{
              marginInlineStart: "auto",
              display: { xs: 'none', md: "flex" },
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
              alignItems: "center"
            }}
          >
            <IconButton onClick={() => setIsCreatorDialogOpen(true)}>
              <PersonAddAlt1OutlinedIcon />
            </IconButton>
            <IconButton onClick={handleResetFilters}>
              <FilterAltOffRounded />
            </IconButton>
            <FormControlLabel
              checked={showPretty}
              onChange={(event) => setShowPretty(event.target.checked)}
              control={<Switch />}
              label="Show Pretty"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginInlineStart: 1,
          display: { xs: 'flex', md: "none" },
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
          alignItems: "center",
          minHeight: '56px',
          marginBottom: 2,
        }}
      >
        <IconButton onClick={() => setIsCreatorDialogOpen(true)}>
          <PersonAddAlt1OutlinedIcon />
        </IconButton>
        <IconButton onClick={handleResetFilters}>
          <FilterAltOffRounded />
        </IconButton>
        <FormControlLabel
          checked={showPretty}
          onChange={(event) => setShowPretty(event.target.checked)}
          control={<Switch />}
          label="Show Pretty"
        />
      </Box>

      <CreatorDialog
        open={isCreatorDialogOpen}
        onClose={() => setIsCreatorDialogOpen(false)}
        onSubmit={handleCreatorSubmit}
      />

      {loading ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : filteredData.length === 0 ? (
        <Box sx={{ textAlign: "center", padding: 3 }}>
          <h3>
            Sorry, there are no creators for this exact set of filters, try
            changing your filters!
          </h3>
        </Box>
      ) : showPretty ? (
        <Box sx={{ paddingInline: { xs: 2, md: 1 }, marginBlockEnd: 1 }}>
          <CreatorContainers
            creators={filteredData.slice(
              page * itemsPerPage,
              Math.min(page * itemsPerPage + itemsPerPage, filteredData.length)
            )}
            platform={platform}
            promotion={promotionType}
            selectedItems={selectedItems}
            onAddToCampaign={handleSelectItem}
            onInviteToPartnership={onInviteToPartnership ? (creator) => handleInviteToPartnership(creator) : null}
          />
        </Box>
      ) : (
        <Box sx={StyleCsvSection}>
          <Paper
            elevation={3}
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
              maxWidth: "100%",
              overflowX: "auto",
            })}
          >
            <Table sx={classes.csvTable}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">Select</TableCell>
                  {platformHeaders.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(
                    page * itemsPerPage,
                    Math.min(
                      page * itemsPerPage + itemsPerPage,
                      filteredData.length
                    )
                  )
                  .map((creator, idx) => (
                    <StyledTableRow
                      key={idx}
                      selected={selectedItems.has(creator.creator)}
                      sx={{
                        maxWidth: 10,
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedItems.has(creator.creator)}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleSelectItem(creator.creator);
                          }}
                          color="primary"
                        />
                      </TableCell>
                      {platformHeaders.map((header) => (
                        <TableCell key={header}>
                          {generateCellContent(creator, header)}
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      )}
      <Paper
        elevation={1}
        sx={{ padding: 1, marginBlockStart: "auto" }}
        square
      >
        <Pagination
          sx={{ marginInline: "auto", maxWidth: "fit-content" }}
          count={Math.ceil(filteredData.length / itemsPerPage)}
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)}
        />
      </Paper>
      {/* Add Collection Dialog */}
      <Dialog
        open={isAddCollectionDialogOpen}
        onClose={() => setIsAddCollectionDialogOpen(false)}
      >
        <DialogTitle>Add New Collection</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Collection Name"
            fullWidth
            value={newCollectionName}
            onChange={(e) => setNewCollectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddCollectionDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveCollection();
              setIsAddCollectionDialogOpen(false);
            }}
            disabled={!newCollectionName}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default LeftColumnSearch;

