import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  Typography,
  ThemeProvider,
  CssBaseline,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import client from "../../API";
import useAuth from "../../Hooks/use-auth";
import { validateEmail, validatePassword } from "../../Utils";
import routes from "../../Config/routes";
import { lightTheme } from "../../Utils/baseTheme";
import HomeHeader from "../../Components/HomeHeader";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const { authorize, setCurrentUser } = useAuth();

  const { mutate: login, isLoading } = useMutation(client.users.login, {
    onSuccess: (data) => {
      console.log("Login in", data);
      authorize(data.token);
      setCurrentUser(data.user);
      navigate(routes.dashboard);
    },
    onError: (error) => {
      console.error("An error occurred during login: ", error);
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network busy. Try again in a few seconds.";
      } else {
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message || errorMessage;
        }
      }
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    },
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setSnackbarMessage("Email field is required!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (!validateEmail(email)) {
      setSnackbarMessage("Invalid email address!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (password.length === 0) {
      setSnackbarMessage("Password field is required!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (!validatePassword(password)) {
      setSnackbarMessage("Password should have 6 or more characters!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    login({
      email,
      password,
    });
  };

  const handleRegister = () => {
    navigate(routes.requestAccess);
  };

  const handleCreatorLogin = () => {
    navigate(routes.creatorConnectStart);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <HomeHeader />
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Welcome back
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Sign in to continue with Blitz
            </Typography>
          </Box>
          <form onSubmit={handleLogin}>
            <TextField
              disabled={isLoading}
              autoComplete="username"
              id="username"
              label="Email"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              disabled={isLoading}
              required
              autoComplete="current-password"
              id="current-password"
              label="Password"
              variant="outlined"
              type="password"
              sx={{ marginBottom: 2 }}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <input type="checkbox" id="remember-me" name="remember-me" />
              <label htmlFor="remember-me" style={{ marginLeft: 8 }}>
                Remember me
              </label>
            </Box>

            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ marginBottom: 2 }}
              startIcon={
                isLoading && <CircularProgress size={20} color="inherit" />
              }
              disabled={isLoading}
              type="submit"
              fullWidth
            >
              Login
            </Button>
          </form>

          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body2">
              Don't have an account?
              <Button color="primary" onClick={handleRegister} sx={{ ml: 1 }}>
                Contact Us
              </Button>
            </Typography>
          </Box>
        </Paper>

        <Box
          sx={{
            textAlign: "center",
            marginTop: 4,
            padding: 2,
            backgroundColor: "#f5f5f5",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">Are you a creator?</Typography>
          <Typography variant="body2" color="textSecondary" mb={1}>
            Sign in here
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={handleCreatorLogin}
          >
            Creator Connect Login
          </Button>
        </Box>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
