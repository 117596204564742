import React from "react";
import { Typography, Box } from "@mui/material";

const InvitedCreators = ({ invitedCreators }) => {
  return (
    <Box>
      <Typography variant="h6">Invited Creators</Typography>
      {invitedCreators && invitedCreators.length ? (
        invitedCreators.map((creator, index) => (
          <Typography key={index}>{creator.name}</Typography>
        ))
      ) : (
        <Typography>No creators have been invited yet.</Typography>
      )}
    </Box>
  );
};

export default InvitedCreators;
