import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  IconButton,
  Button,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  ListItemText,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useMutation } from "react-query";
import { Delete as DeleteIcon } from "@mui/icons-material";
import client from "../../../../API";
import { StyledTableRow } from "../../../../Utils/styledcell";
import CastingCallDialog from "./castingcallsdialog";

const platformOptions = [
  "TikTok",
  "Instagram",
  "YouTube",
  "Podcasts",
  "Twitch",
  "Kick",
  "X",
];

const promotionTypesByPlatform = {
  TikTok: ["Sound", "Brand", "Livestream"],
  Instagram: ["Sound", "Brand", "Feed Post"],
  YouTube: ["3045s Integration", "60s Integration", "Shorts"],
  Podcasts: ["15s", "60s"],
  Twitch: ["Live 30s"],
  Kick: ["Live 30s"],
  X: ["Feed Post", "Repost"],
};

const dummyPhotoLinks = [
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/287262.jpg",
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/wp2468668.jpg",
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/eev9l01j44ab1.jpg",
];

const CastingCalls = () => {
  const [castingCalls, setCastingCalls] = useState([]);
  const [tabValue, setTabValue] = useState("All");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCastingCallId, setSelectedCastingCallId] = useState(null);
  const [open, setOpen] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [newCastingCall, setNewCastingCall] = useState({
    platforms: [],
    promotionTypes: [],
    brief: "",
    budgetMin: 100,
    budgetMax: 500,
  });

  const { mutate: fetchCastingCalls } = useMutation(client.castingCalls.list, {
    onSuccess: (data) => {
      const updatedData = data.map((call) => {
        const randomIndex = Math.floor(Math.random() * dummyPhotoLinks.length);
        const randomImage = dummyPhotoLinks[randomIndex];
        return { ...call, image: randomImage };
      });
      setCastingCalls(updatedData);
    },
    onError: (error) => console.error("Error fetching casting calls:", error),
  });

  const { mutate: updateStatus } = useMutation(
    ({ id, status }) => client.castingCalls.updateStatus(id, { status }),
    {
      onSuccess: fetchCastingCalls,
      onError: (error) =>
        console.error("Error updating casting call status:", error),
    }
  );

  const { mutate: postNewCastingCall } = useMutation(
    client.castingCalls.postNew,
    {
      onMutate: () => setIsPosting(true),
      onSuccess: () => {
        fetchCastingCalls();
        setOpen(false);
        setIsPosting(false);
        setNewCastingCall({
          platforms: [],
          promotionTypes: [],
          brief: "",
          budgetMin: 100,
          budgetMax: 500,
        });
      },
      onError: (error) => {
        console.error("Error creating casting call:", error);
        setIsPosting(false);
      },
    }
  );

  useEffect(() => {
    fetchCastingCalls();
  }, [tabValue]);

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const handleOpen = () => {
    const hasOpenCastingCall = castingCalls.some(
      (call) => call.status === "Active"
    );
    if (hasOpenCastingCall) {
      setBannerVisible(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewCastingCall((prev) => ({ ...prev, [name]: value }));
  };

  const handlePlatformChange = (event) => {
    const { value } = event.target;
    setNewCastingCall((prev) => ({
      ...prev,
      platforms: value,
      promotionTypes: [], // Reset promotion types when platforms change
    }));
  };

  const handlePromotionTypeChange = (event) => {
    const { value } = event.target;
    setNewCastingCall((prev) => ({
      ...prev,
      promotionTypes: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleSliderChange = (field) => (event, value) => {
    setNewCastingCall((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => postNewCastingCall(newCastingCall);

  const handleDelete = (castingCallId) => {
    updateStatus({ id: castingCallId, status: "DELETED" });
  };

  const handleDialogOpen = (castingCallId) => {
    setSelectedCastingCallId(castingCallId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedCastingCallId(null);
  };

  const availablePromotionTypes = newCastingCall.platforms.flatMap(
    (platform) => promotionTypesByPlatform[platform] || []
  );

  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
        sx={{ textAlign: "center", mb: 2 }}
      >
        Casting Calls
      </Typography>

      {bannerVisible && (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          You may not have more than one open casting call. To create a new one,
          you must delete or archive the old one.
        </Alert>
      )}

      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create a Casting Call
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create a New Casting Call</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Enter details for your casting call. Your selected budget range and
            brief will help creators understand the opportunity.
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Platform Type</InputLabel>
            <Select
              multiple
              value={newCastingCall.platforms}
              onChange={handlePlatformChange}
              renderValue={(selected) => selected.join(", ")}
            >
              {platformOptions.map((platform) => (
                <MenuItem key={platform} value={platform}>
                  <Checkbox
                    checked={newCastingCall.platforms.includes(platform)}
                  />
                  <ListItemText primary={platform} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
            disabled={!newCastingCall.platforms.length}
          >
            <InputLabel>Promotion Types</InputLabel>
            <Select
              multiple
              value={newCastingCall.promotionTypes}
              onChange={handlePromotionTypeChange}
              renderValue={(selected) => selected.join(", ")}
            >
              {availablePromotionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox
                    checked={newCastingCall.promotionTypes.includes(type)}
                  />
                  <ListItemText primary={type} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Brief"
            name="brief"
            fullWidth
            multiline
            rows={4}
            onChange={handleInputChange}
            margin="dense"
          />
          <Typography gutterBottom>
            Minimum Budget: ${newCastingCall.budgetMin}
          </Typography>
          <Slider
            value={newCastingCall.budgetMin}
            onChange={handleSliderChange("budgetMin")}
            min={50}
            max={newCastingCall.budgetMax - 1}
            step={1}
            valueLabelDisplay="auto"
          />
          <Typography gutterBottom>
            Maximum Budget: ${newCastingCall.budgetMax}
          </Typography>
          <Slider
            value={newCastingCall.budgetMax}
            onChange={handleSliderChange("budgetMax")}
            min={newCastingCall.budgetMin + 1}
            max={10000}
            step={1}
            valueLabelDisplay="auto"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={isPosting}>
            {isPosting ? <CircularProgress size={24} /> : "Create"}
          </Button>
        </DialogActions>
      </Dialog>

      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="All" value="All" />
        <Tab label="Active" value="Active" />
        <Tab label="Completed" value="Completed" />
      </Tabs>

      <Grid container spacing={2}>
        {castingCalls.map((call) => (
          <Grid item xs={12} sm={6} md={4} key={call.id}>
            <Card onClick={() => handleDialogOpen(call.id)}>
              <CardMedia
                component="img"
                height="140"
                image={call.image}
                alt="Casting Call Image"
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  {call.platforms.join(", ")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {call.brief}
                </Typography>
                <Typography variant="body2">
                  Budget: ${call.budgetMin} - ${call.budgetMax}
                </Typography>
              </CardContent>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(call.id);
                }}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>

      <CastingCallDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        castingCallId={selectedCastingCallId}
      />
    </Box>
  );
};

export default CastingCalls;
