import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Container,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom"; // Also import useLocation
import { Google as GoogleIcon } from "@mui/icons-material";  // Import Google icon
import blitzLogo from "../../Components/globalAssets/platty.png";
import routes from "../../Config/routes.js";
import ConsentForm from "./popups/consentform.js";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { ConfigValue } from "../../Config";
import BlitzHeader from "../../Components/BlitzHeader.js";
import AlertDialog from "../../Components/AlertDialog.js";
import useAlertDialog from "../../Components/useAlertDialog.js";
import API from "../../API/index.js";

const CreatorConnect = () => {
  const navigate = useNavigate();
  const { creator } = useParams();
  const location = useLocation();
  const [creatorName, setCreatorName] = useState(sessionStorage.getItem("creatorName") || creator || "");
  const [paymentMethod, setPaymentMethod] = useState(
    sessionStorage.getItem("paymentMethod") || "PayPal",
  );
  const [paymentEmail, setPaymentEmail] = useState("");
  const [stripeUserId, setStripeUserId] = useState(
    sessionStorage.getItem("stripeUserId"),
  );
  const [status, setStatus] = useState("Partner"); // default status is Partner
  const [consentOpen, setConsentOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [googleToken, setGoogleToken] = useState(null);
  const { dialogState, openDialog } = useAlertDialog();
  const [googleRequest, setGoogleRequest] = useState('')

  const scope = [
    'https://www.googleapis.com/auth/gmail.modify',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email'
  ];

  const scopeString = scope.reduce((accum, currSco, currIdx) => (accum + (currIdx == 0 ? '' : ' ') + currSco), '');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stripeIdFromUrl = queryParams.get("code");
    sessionStorage.setItem("creatorName", creatorName);
    if (stripeIdFromUrl) {
      setStripeUserId(stripeIdFromUrl);
      sessionStorage.setItem("stripeUserId", stripeIdFromUrl);
    }
  }, []);

  const googleLogin = useGoogleLogin({
    onError: errorResponse => { alert(`Error getting Partner permissions: ${errorResponse}`) },
    scope: scopeString,
    flow: "auth-code",
    ux_mode: 'redirect',
    redirect_uri: 'https://blitzpay.pro/creatorconnect/redirect',
    state: googleRequest
  });

  const handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    setPaymentMethod(method);
    sessionStorage.setItem("paymentMethod", method);
    sessionStorage.setItem("creatorName", creatorName);
    if (method === "Stripe") {
      const redirectUri = `https://useblitz.co/creatorconnect/redirect`;
      window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${encodeURIComponent(redirectUri)}`;
    }
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setStatus(selectedStatus);
    if (selectedStatus === "Partner") {
      setConsentOpen(true); // Open consent form dialog
    }
  };
  const handleConsentClose = (consented) => {
    setConsentOpen(false);
    if (!consented) {
      setStatus("Associate"); // Revert status if not consented
    }
  };

  function handleCredentialResponse(response) {
    setGoogleToken(response.credential);
    setIsAuthenticated(true);
  }

  const handleLoginFailure = (error) => {
    console.error('Failed to login with Google:', error);
    alert('Google login failed, please try again.');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      username: creatorName,
      payout_preferred: paymentMethod,
      email: paymentEmail,
      stripe_id: stripeUserId,
      google_token: googleToken,
      status: status
    };

    try {
      const data = await API.creatorConnect.connect(payload);
      if (status === "Partner") {
        setGoogleRequest(data.request_id);
        openDialog("Google Authorization", "Please authorize access to your Google Account", googleLogin, googleLogin, "Ok", null);
        return;
      }
      navigate('/start');  // Redirect to /start after successful submission
    } catch (error) {
      console.error("Error during form submission:", error);
      alert(`${error.response?.data?.error || error.message} \nYour Google and payment information has been blanked. Try again.`);
      setGoogleToken(null);
      setPaymentMethod("PayPal");
      sessionStorage.setItem("paymentMethod", "PayPal");
      setStripeUserId(null);
      sessionStorage.removeItem("stripeUserId");
      setIsAuthenticated(false);
    }
  };

  return (
    <>
      <BlitzHeader>Creator Connect</BlitzHeader>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Container maxWidth="md">
        <h1>Creator Connect Form</h1>
        <form id="creatorForm" onSubmit={handleFormSubmit}>
          <TextField label="Creator Name" fullWidth value={creatorName} />
          <FormControl fullWidth>
            <InputLabel>Payment Method</InputLabel>
            <Select value={paymentMethod} onChange={handlePaymentMethodChange}>
              <MenuItem value="PayPal">PayPal, Standard, Fees Apply</MenuItem>
              <MenuItem value="Stripe">BlitzPay, Faster, Less Fees</MenuItem>
            </Select>
          </FormControl>
          {paymentMethod === "PayPal" && (
            <TextField
              label="PayPal Email"
              fullWidth
              value={paymentEmail}
              onChange={(e) => setPaymentEmail(e.target.value)}
            />
          )}
          {paymentMethod === "Stripe" && (
            <TextField
              label="Stripe User ID"
              fullWidth
              value={stripeUserId}
              disabled // make it read-only
            />
          )}
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select value={status} onChange={handleStatusChange}>
              <MenuItem value="Partner">Partner</MenuItem>

              <MenuItem value="Associate">Associate</MenuItem>
            </Select>
          </FormControl>
          {status != "Partner" && <GoogleLogin
            onSuccess={handleCredentialResponse}
            onError={handleLoginFailure}
            useOneTap />}
          {
            status == "Partner" && <Typography variant="caption" component={'h2'}>As a Partner, you'll log with your Google account when clicking Submit</Typography>
          }
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<GoogleIcon />}  // Add Google icon here
            disabled={!isAuthenticated && status != "Partner"}
          >
            Submit with Google
          </Button>
        </form>
      </Container>

      <ConsentForm open={consentOpen} onClose={handleConsentClose} />
    </>
  );
};

export default CreatorConnect;
