import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Tabs,
  Tab,
} from '@mui/material';
import { useMutation } from 'react-query';
import { useCreatorAuth } from '../../../../Hooks/creator-use-auth';
import client from '../../../../API';

const CreatorCastingCalls = () => {
  const { creatorToken } = useCreatorAuth();
  const token = creatorToken?.token;
  const username = creatorToken?.creator_user?.username;

  const [castingCalls, setCastingCalls] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('Active');
  const [isFetching, setIsFetching] = useState(false);

  // Fetch casting calls using mutate
  const { mutate: fetchCastingCalls } = useMutation(client.castingCalls.creatorFetch, {
    onMutate: () => setIsFetching(true),
    onSuccess: (data) => {
      setCastingCalls(data || []);
      setIsFetching(false);
    },
    onError: (error) => {
      console.error('Error fetching casting calls:', error);
      setIsFetching(false);
    },
  });

  // Apply for a casting call
  const { mutate: applyToCastingCall } = useMutation((castingCallId) =>
    client.castingCalls.creatorApply(castingCallId), {
      onSuccess: () => {
        alert('Application successful');
        fetchCastingCalls(token);
      },
      onError: (error) => {
        console.error('Error applying to casting call:', error);
        alert('An error occurred while applying');
      },
    }
  );
  

  useEffect(() => {
    if (token) {
      fetchCastingCalls(token);
    }
  }, [token]);

  const filteredCastingCalls = castingCalls.filter((castingCall) => {
    switch (currentFilter) {
      case 'Active':
        return castingCall.status === 'Active';
      case 'Applied':
        return castingCall.applications?.includes(username);
      case 'Completed':
        return castingCall.status === 'Completed';
      default:
        return true;
    }
  });

  const handleApply = (castingCallId) => {
    if (!token) {
      alert('Authentication required to apply.');
      return;
    }
    applyToCastingCall(castingCallId); // Pass just the ID
  };
  

  return (
    <Box>
      <Paper elevation={2} square>
        <Tabs value={currentFilter} onChange={(e, val) => setCurrentFilter(val)} centered>
          <Tab label="Active" value="Active" />
          <Tab label="Applied" value="Applied" />
          <Tab label="Completed" value="Completed" />
        </Tabs>
      </Paper>
      <Typography variant="h6" sx={{ p: 2 }}>
        Available Casting Calls
      </Typography>
      {isFetching ? (
        <CircularProgress sx={{ color: 'white' }} />
      ) : (
        <Grid container spacing={3} sx={{ padding: 2 }}>
          {filteredCastingCalls.length > 0 ? (
            filteredCastingCalls.map((castingCall) => (
              <Grid item xs={12} sm={6} md={4} key={castingCall.id}>
                <Card>
                  <CardHeader
                    title={`Casting Call #${castingCall.id} (${castingCall.status})`}
                    subheader={`Budget: $${castingCall.budget_min} - $${castingCall.budget_max}`}
                  />
                  <CardContent>
                    <Typography variant="body2">
                      Platforms: {castingCall.platforms?.join(', ') || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      Promotion Types: {castingCall.promotion_types?.join(', ') || 'N/A'}
                    </Typography>
                    <Typography variant="body2">Brief: {castingCall.brief || 'No details provided.'}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button variant="contained" color="primary" onClick={() => handleApply(castingCall.id)}>
                      Apply
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography>No casting calls available under the selected filter.</Typography>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default CreatorCastingCalls;
