import React from "react";
import { Box, Button, Typography } from "@mui/material";
import marketplaceImage from "../../Components/globalAssets/logo_placeholder.png"; // Replace with actual image path
import { useNavigate } from "react-router-dom";
import routes from "../../Config/routes"; // Import the routes object
import { globalStyles } from "../../Utils/Styles";

const MarketplaceSection = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Box
      sx={{
        ...globalStyles.wholeHeightMinusTop,
        display: "flex",
        flexDirection: { xs: "column", md: "row-reverse" }, // Flipped layout
        justifyContent: 'space-evenly',
        alignItems: "center",
        color: "#000", // Light mode text color
        maxWidth: "100%", // Set to full width to remove any side margins

      }}
    >
      {/* Right Section with Image (flipped) */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: { xs: 4, md: 0 },
          maxWidth:{xs: '300px', md:'400px'}
        }}
      >
        <img
          src={marketplaceImage} // Use your marketplace-related image here
          alt="Marketplace"
          style={{
            width: "100%",

          }}
        />
      </Box>

      {/* Left Section with Text (flipped) */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: { xs: "center", md: "flex-start" },
          textAlign: { xs: "center", md: "left" },
          maxWidth: '30rem'
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
          Access our global creator marketplace
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Explore a worldwide network of content creators and brands. Blitz seamlessly connects everyone all in one place.
        </Typography>
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#7b2a2a", // Light mode primary color
            color: "#fff",
          }}
          onClick={handleAccess}
        >
          Request Access
        </Button>
      </Box>
    </Box>
  );
};

export default MarketplaceSection;
