import { createSvgIcon } from "@mui/material";

const InstagramIcon = createSvgIcon(
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9903 20H3.67617C1.83633 20 0.333252 18.4969 0.333252 16.6571V3.34292C0.333252 1.50308 1.83633 0 3.67617 0H16.9903C18.8302 0 20.3333 1.50308 20.3333 3.34292V16.6571C20.3333 18.5051 18.8384 20 16.9903 20Z" fill="currentColor" />
        <path d="M10.3373 15.1376C8.96566 15.1376 7.67614 14.6037 6.70694 13.6345C5.73774 12.6653 5.20386 11.3758 5.20386 10.0041C5.20386 8.63247 5.73774 7.34295 6.70694 6.37375C7.67614 5.40455 8.96566 4.87067 10.3373 4.87067C11.709 4.87067 12.9985 5.40455 13.9677 6.37375C14.9369 7.34295 15.4708 8.63247 15.4708 10.0041C15.4708 11.3758 14.9369 12.6653 13.9677 13.6345C12.9903 14.6037 11.709 15.1376 10.3373 15.1376ZM10.3373 5.96307C8.11145 5.96307 6.29626 7.77005 6.29626 10.0041C6.29626 12.23 8.10324 14.0452 10.3373 14.0452C12.5632 14.0452 14.3784 12.2382 14.3784 10.0041C14.3702 7.77826 12.5632 5.96307 10.3373 5.96307Z" fill="white" />
        <path d="M16.4715 4.70195C17.0159 4.70195 17.4571 4.26066 17.4571 3.7163C17.4571 3.17194 17.0159 2.73065 16.4715 2.73065C15.9271 2.73065 15.4858 3.17194 15.4858 3.7163C15.4858 4.26066 15.9271 4.70195 16.4715 4.70195Z" fill="white" />
    </svg>
    ,
    'Instagram',
);

export default InstagramIcon