import React, { useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import API from "../../../API";
import { globalStyles } from "../../../Utils/Styles";
import InternalBookingDialog from "./steps/partnershipsbooking";
import { useMutation, useQuery } from "react-query";
import MarketplaceBooking from "./MarketplaceBooking";
import { getPlatformAndPromotion, platformToFollowing, platformToLink, sortCreators } from "../../../Utils/constants";
import AlertDialog from "../../../Components/AlertDialog";
import useAlertDialog from "../../../Components/useAlertDialog";
import { useNavigate } from "react-router-dom";

const SearchPage = () => {

  const [isBookingDialogOpen, setIsBookingDialogOpen] = useState(false);
  const [selectedCreatorForBooking, setSelectedCreatorForBooking] = useState(null);
  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const navigate = useNavigate();

  const { data: creatorList, isFetching: creatorIsLoading } = useQuery({
    queryKey: ['creators'],
    queryFn: async () => sortCreators(await API.creators.list({ include_reviews: true, include_public: true })),
    refetchOnWindowFocus: false,
    initialData: [],
  });

  const { mutate: postCampaign, isLoading: loadingCampaign } = useMutation({
    mutationFn: API.campaigns.create,
    onError: error => {
      openDialog('Error', <>An error ocurred while creating your campaign: <br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, "Ok", null);
    },
    onSuccess: data => {
      console.log(data);
      openDialog('Campaign Builder', <>Your campaign has been created succesfully</>, () => navigate(`/editcampaign/${data.id}`), () => navigate(`/editcampaign/${data.id}`), "Ok", null);
    }
  })

  const handleInviteToPartnership = (creator) => {
    setSelectedCreatorForBooking(creator);  // Pass full creator data
    setIsBookingDialogOpen(true);
  };

  const { mutate: postPartnership, isLoading: loadingPartnership } = useMutation(
    API.partnerships.create,
    {
      onError: error => {
        openDialog('Error', <>An error ocurred while creating your partnership: <br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, "Ok", null);
      },
      onSuccess: data => {
        console.log(data);
        openDialog('Partnership Builder', <>Your partnership has been created succesfully</>, () => navigate(`/partnerships/${data.data.id}`), () => navigate(`/partnerships/${data.data.id}`), "Ok", null);
      }
    }
  );

  const submitBooking = (bookingDetails) => {
    postPartnership({
      name: `${bookingDetails.partnershipName} - ${bookingDetails.promotionPlatform} - ${bookingDetails.promotionType}`,
      creator: selectedCreatorForBooking.id,
      description: bookingDetails.details,
      proposal_date: bookingDetails.selectedDate,
      total_sum: bookingDetails.rate,
      notes: bookingDetails.details,
      finish_date: bookingDetails.selectedDate
    })
  };

  const onFinishCampaign = (campaign) => {

    const newCreators = campaign.creatorsData.map((creator) => {
      const price = parseFloat(creator.adjustedPrice);
      const platPromo = getPlatformAndPromotion(creator.rateKey)
      return {
        id: creator.creator,
        name: creator.creator,
        price: price,
        agencyRate: campaign.isBlitzFeeOnCreators ? price * 0.95 : price,
        following: creator[platformToFollowing[platPromo.platform]],
        promotionPlatform: platPromo.platform,
        promotionType: platPromo.promotion,
        platformLink: creator[platformToLink[platPromo.platform]],
        pfphref: creator.pfphref,
      };
    });

    const timelineEvents = Object.entries(campaign.timelinesData).flatMap(([creatorId, events]) => events.map((event) => ({ ...event, username: creatorId })))

    const projectData = {
      name: campaign.campaignData.campaignName,
      brief: campaign.campaignData.asset ? campaign.campaignData.asset.file_url : campaign.campaignData.campaignBrief, // Replace brief with asset URL if available
      videoAmount: parseInt(1, 10),
      creators: newCreators,
      timelines: timelineEvents,
      idealDueDate: campaign.campaignData.idealDueDate,
      emailRecipient: campaign.campaignData.emailRecipient,
      agency: campaign.campaignData.isAgency,
      campaign_manager: campaign.campaignData.manager,
    };

    postCampaign(projectData);
  };

  return (
    <>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={loadingCampaign||loadingPartnership}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        id="search-holder"
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        <MarketplaceBooking
          onInviteToPartnership={(creator) => handleInviteToPartnership(creator)}
          data={creatorList}
          loading={creatorIsLoading}
          onSubmitCampaign={onFinishCampaign}
        />
      </Box>
      <InternalBookingDialog
        open={isBookingDialogOpen}
        onClose={() => setIsBookingDialogOpen(false)}
        submitBooking={submitBooking}
        creatorData={{ ...selectedCreatorForBooking}}
      />

    </>
  );
};

export default SearchPage;
