import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Toolbar,
} from "@mui/material";
import frame from "../../Components/globalAssets/frame.png";
import videoOnRight from "../../Components/globalAssets/video.mp4";
import PaymentSection from "./homeExtended";
import MarketplaceSection from "./marketplace";
import AIManagerSection from "./aimanager";
import CreatorBanner from "./topcreators";
import ClientsSection from "./clientSection";
import routes from "../../Config/routes";
import { globalStyles } from "../../Utils/Styles";
import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";

const Home = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (

    <Box
      sx={{
        width: '100%',
        color: "#000", // Light mode text color
        backgroundColor: "#FAF9F6", // Light mode background color
      }}
    >
      <HomeHeader></HomeHeader>
      <Toolbar></Toolbar>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}

      >
        {/* Main content section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            px: 2,
            ...globalStyles.wholeHeightMinusTop
          }}
        >
          {/* Left Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: '40rem',
              alignItems: { xs: "center", md: "flex-start" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
              Craft AI-enabled influencer campaigns without costly subscription
              fees
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              Sourcing creators is hard, but you don’t have to do it alone. Blitz
              allows you to source, communicate with, and pay creators all in one
              place.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#7b2a2a", // Light mode primary color
                color: "#fff",
              }}
              onClick={handleAccess}
            >
              Request Access
            </Button>
          </Box>

          {/* Right Section with custom frame */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" }, // Hide on mobile, show on desktop
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              maxWidth: "100%", // Prevent overflow on mobile
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "300px", // Fixed width for desktop
                height: "600px", // Fixed height for desktop
                rotate: '4.96deg',
                boxShadow: '-6px 6px 12px black',
                borderRadius: '48px',

              }}
            >
              <img
                src={frame}
                alt="Frame"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
              <video
                src={videoOnRight} // Replace with the source URL or path to your video
                autoPlay
                loop
                muted
                style={{
                  width: "88%",
                  height: "94%",
                  objectFit: "cover",
                  position: "absolute",
                  left: "6%",
                  top: "3%",
                  borderRadius: '4%',
                  zIndex: 0,
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Content Sections */}
        <PaymentSection />
        <CreatorBanner />
        <MarketplaceSection />
        <ClientsSection />
        <AIManagerSection />

        <HomeFooter></HomeFooter>
      </Box>
    </Box>
  );
};

export default Home;
