import { InputAdornment, TextField } from '@mui/material';
import React, {useState} from 'react'

export function RateField({ onChange, minValue, error, helperText, ...allProps }) {

    const [internalError, setInternalError] = useState('');
    const handleInputChange = (event) => {
        if (/^\d*(?:[.,]\d{0,2})?$/.test(event.target.value)) {  // Allow only valid number inputs with 2 decimal places
            if(minValue && event.target.valueAsNumber < minValue)
                setInternalError(`This value can't be lower than ${minValue}`);
            else
                setInternalError("");
            onChange(event.target.value);
        }
    }

    return (
        <TextField
            {...allProps}
            type='number'
            InputProps={{ startAdornment: <InputAdornment position="start">USD</InputAdornment>}}
            inputMode='decimal'
            inputProps={{min:minValue}}
            onChange={handleInputChange}
            error={(!!internalError)||error}
            helperText={helperText || internalError}
        />
    )
}

