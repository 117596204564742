import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Switch,
  IconButton,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  TableCell,
  TableBody,
  CircularProgress,
  CardActions,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import client from "../../../API";
import profilePhoto from "../../../Components/globalAssets/ppfLogo.png";
import { StyledTableRow, StyledTableCell } from "../../../Utils/styledcell";
import useAuth from "../../../Hooks/use-auth";
import { grey } from '@mui/material/colors';

function formatCampaignSum(sum) {
  const numericSum =
    typeof sum === "number"
      ? sum
      : parseFloat(sum.replace(/[^\d.]/g, ""));
  return `$${numericSum
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

const Collaborations = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tabValue, setTabValue] = useState('All');
  const [isPrettyView, setIsPrettyView] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [actionStatus, setActionStatus] = useState('');
  const [managers, setManagers] = useState([]);
  const [currentManager, setCurrentManager] = useState("");
  const navigate = useNavigate();

  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const currentUserId = currentUser.id;
  const userCompany = currentUser.company_name;
  const isTcc = userCompany === "TCC";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.companies.listUsers();
        setManagers(data);
      } catch (error) {
        setManagers([]);
      }
    };
    if (!isTcc) return;
    fetchData();
  }, [isTcc]);

  const { data: campaignsData, isLoading: isLoadingCampaigns, refetch: fetchCampaigns } = useQuery(
    'campaigns',
    client.campaigns.list,
    {
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  const { data: partnershipsData, isLoading: isLoadingPartnerships, refetch: fetchPartnerships } = useQuery(
    'partnerships',
    client.partnerships.list,
    {
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  const [collaborations, setCollaborations] = useState([]);

  useEffect(() => {
    if (campaignsData && partnershipsData) {
      const campaignsWithType = campaignsData
        .filter(campaign => campaign.campaign_status !== 'Archived') // Exclude archived campaigns
        .map(campaign => ({
          ...campaign,
          type: 'campaign',
          // Include pfphref from the first creator if available
          pfphref: campaign.creators && campaign.creators.length > 0 && campaign.creators[0].pfphref
            ? campaign.creators[0].pfphref
            : profilePhoto,
        }));
      const partnershipsWithType = partnershipsData
        .filter(partnership => partnership.status !== 'DELETED' && partnership.status !== 'Archived') // Exclude deleted partnerships
        .map(partnership => ({
          ...partnership,
          type: 'partnership',
          pfphref: partnership.pfphref || profilePhoto,
        }));
      const combinedCollaborations = [...campaignsWithType, ...partnershipsWithType];
      setCollaborations(combinedCollaborations);
    }
  }, [campaignsData, partnershipsData]);

  const loading = isLoadingCampaigns || isLoadingPartnerships;

  // Function to get the date for sorting
  function getSortDate(item) {
    const proposalDate = new Date(item.proposal_date);
    const finishDate = item.finish_date ? new Date(item.finish_date) : null;

    if (!isNaN(proposalDate.getTime())) {
      return proposalDate;
    } else if (finishDate && !isNaN(finishDate.getTime())) {
      return finishDate;
    } else {
      return new Date(0); // Default to epoch if no valid date
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredCollaborations = collaborations.filter((item) => {
    // Exclude archived campaigns and deleted partnerships
    if ((item.type === 'campaign' && item.campaign_status === 'Archived') ||
      (item.type === 'partnership' && item.status === 'DELETED')) {
      return false;
    }

    // Filter by tab value
    let tabMatch = true;

    if (tabValue === 'Campaigns') {
      tabMatch = item.type === 'campaign';
    } else if (tabValue === 'Partnerships') {
      tabMatch = item.type === 'partnership';
    } else if (tabValue === 'Active/Launched') {
      if (item.type === 'campaign') {
        tabMatch = item.campaign_status === 'Active' || item.campaign_status === 'Launched';
      } else if (item.type === 'partnership') {
        tabMatch = item.status === 'Active' || item.status === 'Launched';
      }
    } else if (tabValue === 'Pending/Draft') {
      if (item.type === 'campaign') {
        tabMatch = item.campaign_status === 'Pending' || item.campaign_status === 'Draft';
      } else if (item.type === 'partnership') {
        tabMatch = item.status === 'Pending' || item.status === 'Draft';
      }
    } else if (tabValue === 'Archived') {
      if (item.type === 'campaign') {
        tabMatch = item.campaign_status === 'Archived';
      } else if (item.type === 'partnership') {
        tabMatch = item.status === 'Archived';
      }
    }

    // Filter by search query
    const searchMatch = item.name.toLowerCase().includes(searchQuery.toLowerCase());

    // Apply manager filter for campaigns if isTcc is true
    let managerMatch = true;
    if (isTcc && item.type === 'campaign' && currentManager !== '') {
      managerMatch = item.campaign_manager && item.campaign_manager.email === currentManager;
    }

    return tabMatch && searchMatch && managerMatch;
  });

  // Sort the collaborations by date
  const sortedCollaborations = filteredCollaborations.sort((a, b) => {
    const dateA = getSortDate(a);
    const dateB = getSortDate(b);

    if (dateB - dateA !== 0) {
      return dateB - dateA; // Sort by proposal_date (most recent first)
    } else {
      const finishDateA = a.finish_date ? new Date(a.finish_date) : null;
      const finishDateB = b.finish_date ? new Date(b.finish_date) : null;

      if (finishDateA && finishDateB) {
        return finishDateA - finishDateB; // Sort by finish_date (earliest first)
      } else {
        return 0; // If proposal_date and finish_date are equal or unavailable
      }
    }
  });

  const { mutate: deleteCampaign } = useMutation(client.campaigns.delete, {
    onSuccess: () => {
      alert("Campaign deleted successfully!");
      fetchCampaigns();
    },
    onError: (error) => {
      console.error("Error deleting campaign:", error);
      alert("Error deleting campaign: " + error.message);
    },
  });

  const {
    mutate: updateCampaignStatus,
    isLoading: isUpdatingCampaignStatus,
  } = useMutation(client.campaigns.update, {
    onSuccess: (data) => {
      alert("Campaign status updated successfully!");
      fetchCampaigns();
    },
    onError: (error) => {
      console.error("Error updating campaign:", error);
      alert("Error updating campaign: " + error.message);
    },
  });

  const { mutate: updatePartnershipStatus } = useMutation(
    ({ id, status }) => client.partnerships.updateStatus(id, { status }),
    {
      onSuccess: () => {
        fetchPartnerships();
      },
      onError: (error) => {
        console.error("Error updating partnership status:", error);
        alert("Error updating partnership: " + error.message);
      },
    }
  );

  const applyAction = () => {
    if (selectedIds.length === 0) {
      alert("Please select items to apply action.");
      return;
    }
    if (actionStatus.length === 0) {
      alert("Please choose an action.");
      return;
    }

    selectedIds.forEach((id) => {
      const [type, itemId] = id.split('-');
      if (type === 'campaign') {
        if (actionStatus === 'delete' || actionStatus === 'DELETED') {
          deleteCampaign({ id: itemId });
        } else {
          const status = actionStatus;
          updateCampaignStatus({ campaignIds: [itemId], status });
        }
      } else if (type === 'partnership') {
        if (actionStatus === 'delete' || actionStatus === 'DELETED') {
          updatePartnershipStatus({ id: itemId, status: 'DELETED' });
        } else {
          updatePartnershipStatus({ id: itemId, status: actionStatus });
        }
      }
    });

    setSelectedIds([]);
  };

  const handleDelete = (item) => {
    if (item.type === 'campaign') {
      deleteCampaign({ id: item.id });
    } else if (item.type === 'partnership') {
      updatePartnershipStatus({ id: item.id, status: 'DELETED' });
    }
  };

  const handleItemClick = (item) => {
    if (item.type === 'campaign') {
      navigate(`/editcampaign/${item.id}`);
    } else if (item.type === 'partnership') {
      navigate(`/partnerships/${item.id}`);
    }
  };

  const handleSelectChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleShareCampaign = useCallback((campaignId) => {
    const url = `${window.location.origin}/campaigns/${campaignId}`;
    navigator.clipboard.writeText(url);
    alert("Share link copied to clipboard!");
  }, []);

  const handleCopyCampaign = useCallback(async (campaignId) => {
    try {
      const data = await client.campaigns.copy({ campaignId: campaignId });
      alert(data.message);
      fetchCampaigns();
    } catch (error) {
      console.error("Failed to copy campaign:", error);
      alert(`Error copying campaign:\n${error?.response?.data?.error || error.message}`);
    }
  }, [fetchCampaigns]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ padding: 2 }}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ textAlign: "center", mb: 2 }}
          >
            Your Collaborations
          </Typography>

          {/* Tabs */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="All" value="All" />
            <Tab label="Campaigns" value="Campaigns" />
            <Tab label="Partnerships" value="Partnerships" />
            <Tab label="Active/Launched" value="Active/Launched" />
            <Tab label="Pending/Draft" value="Pending/Draft" />
            <Tab label="Archived" value="Archived" />
          </Tabs>

          {/* Filters */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              my: 4,
              width: "100%",
              boxSizing: "border-box",
              overflowX: "auto",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search by name"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              sx={{ minWidth: 240 }}
            />

            <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
              <InputLabel id="action-status-label">Change Status To</InputLabel>
              <Select
                labelId="action-status-label"
                value={actionStatus}
                onChange={(event) => setActionStatus(event.target.value)}
                label="Change Status To"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Archived">Archived</MenuItem>
                <MenuItem value="DELETED">Delete</MenuItem>
              </Select>
            </FormControl>

            {isTcc && managers && managers.length > 0 && (
              <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
                <InputLabel id="label-managers">Manager</InputLabel>
                <Select
                  value={currentManager}
                  onChange={(e) => setCurrentManager(e.target.value)}
                  label="Manager"
                >
                  <MenuItem value={""}>None</MenuItem>
                  {managers.map((manager, index) => (
                    <MenuItem value={manager.email} key={index}>
                      {manager.first_name} {manager.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Typography>Make it Pretty</Typography>
            <Switch
              checked={isPrettyView}
              onChange={(event) => setIsPrettyView(event.target.checked)}
              color="secondary"
            />

            <Button
              variant="contained"
              onClick={applyAction}
              disabled={selectedIds.length === 0}
            >
              Apply Status Change
            </Button>
          </Box>

          {/* Display content */}
          {isPrettyView ? (
            <Grid container spacing={2}>
              {sortedCollaborations.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={`${item.type}-${item.id}`}>
                  <Card sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      sx={{
                        backgroundColor: item.type === 'campaign' ? grey[300] : grey[500],
                        color: 'white',
                        padding: '4px',
                        textAlign: 'center',
                      }}
                    >
                      {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                    </Box>

                    {/* Display pfphref if available */}
                    {item.pfphref && (
                      <CardMedia
                        component="img"
                        height="300"
                        image={item.pfphref || profilePhoto}
                        alt={item.name}
                        onClick={() => handleItemClick(item)}
                        style={{ cursor: "pointer" }}
                      />
                    )}

                    <CardContent
                      onClick={() => handleItemClick(item)}
                      style={{ cursor: "pointer", flexGrow: 1 }}
                    >
                      <Typography variant="h5" component="div">
                        {item.name}
                      </Typography>
                      {item.type === 'campaign' && (
                        <>
                          <Typography variant="body2">
                            Sum: {formatCampaignSum(item.campaign_sum)}
                          </Typography>
                          <Typography variant="body2">
                            Status: {item.campaign_status}
                          </Typography>
                          
                        </>
                      )}
                      {item.type === 'partnership' && (
                        <>
                          <Typography variant="body2">
                            Creator: {item.creator}
                          </Typography>
                          <Typography variant="body2">
                            Total Sum: {item.total_sum ? `$${parseFloat(item.total_sum).toFixed(2)}` : "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            Status: {item.status}
                          </Typography>
                          
                        </>
                      )}
                    </CardContent>

                    {item.type === 'campaign' ? (
                      <CardActions>
                        <Checkbox
                          checked={selectedIds.includes(`${item.type}-${item.id}`)}
                          onChange={() => handleSelectChange(`${item.type}-${item.id}`)}
                        />
                        <Button
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShareCampaign(item.id);
                          }}
                        >
                          Share
                        </Button>
                        <Button
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopyCampaign(item.id);
                          }}
                        >
                          Copy
                        </Button>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(item);
                          }}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardActions>
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Checkbox
                          checked={selectedIds.includes(`${item.type}-${item.id}`)}
                          onChange={() => handleSelectChange(`${item.type}-${item.id}`)}
                        />
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(item);
                          }}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <TableContainer>
              <Table aria-label="collaborations table" width={"100%"}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Select</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>By User</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedCollaborations.length > 0 ? (
                    sortedCollaborations.map((item) => (
                      <StyledTableRow
                        key={`${item.type}-${item.id}`}
                        hover
                        onClick={() => handleItemClick(item)}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            checked={selectedIds.includes(`${item.type}-${item.id}`)}
                            onChange={() => handleSelectChange(`${item.type}-${item.id}`)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</StyledTableCell>
                        <StyledTableCell>{item.name}</StyledTableCell>
                        <StyledTableCell>
                          {item.type === 'campaign' ? item.campaign_status : item.status}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.user ? item.user.username : 'N/A'}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(item);
                            }}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow>
                      <StyledTableCell colSpan="6" align="center">
                        No collaborations available
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
    </>
  );
};

export default Collaborations;
